import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

export default function SearchComponent(props) {
    function focusSearch() {
        document.getElementById('search').focus();
    }

    return (
        <React.Fragment>
            <IconButton className='cast-toolbar__search-icon' onClick={() => props.searchToggle(true)}
                        edge="end">
                <SearchIcon/>
            </IconButton>
            <FormControl className='cast-toolbar__search' variant="outlined" size='small'>
                <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                <OutlinedInput
                    id="search"
                    type='search'
                    size='small'
                    autoFocus={props.isOpen}
                    value={props.searchInput}
                    onChange={(e) => props.valueChange(e.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton edge="start" onClick={() => focusSearch()}>
                                <SearchIcon/>
                            </IconButton>
                            <IconButton onClick={() => props.searchToggle(false)}
                                        edge="end">
                                <CloseIcon/>
                            </IconButton>
                        </InputAdornment>
                    }
                    labelWidth={50}
                />
            </FormControl>
        </React.Fragment>
    );
}
