import React, { useEffect } from 'react';
import { Button, Checkbox, Chip, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField, Tooltip } from "@material-ui/core";
import { Link } from 'react-router-dom';
import AddCommentIcon from "@material-ui/icons/AddComment";
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import ExportConfirmationComponent from '../export-confirmation/export-confirmation.component';
import UnpublishConfirmationComponent from "../unpublish-confirmation/unpublish-confirmation.component";
import API from '../../api';

export default function TestComponent(props) {
    const [selectedData, setSelectedData] = React.useState(undefined);
    const [courseData, setCourseData] = React.useState(undefined);
    const [hasGoogleAuthorizationTokens, setHasGoogleAuthorizationTokens] = React.useState(false);
    const [reports, setReports] = React.useState([]);

    const editCourse = (id) => {
        props.history.push(`/app/tests/${id}/edit`);
    }

    const unpublishCourse = () => {
        props.unpublish(props.match.params.id);
    }

    const handleExportToGoogleClassroom = (enrollmentCode) => {
        return props.exportToGoogleClassroom(props.match.params.id, enrollmentCode)
            .then((_) => {
                setSelectedData({ ...selectedData, exportedToGoogle: true });
                props.setUpdatedTestData();
            });
    }

    const setUserData = () => {
        API.Auth.getGoogleAuthTokens().then(({ data }) => {
            setHasGoogleAuthorizationTokens(data);
        });
    }

    useEffect(() => {
        let mounted = true;
        if (!selectedData) {
            API.Tests.getTests(props.match.params.id).then(({ data }) => {
                if (mounted) {
                    setSelectedData(data);
                    setCourseData(data.questions);
                }
            });
            API.Tests.getTestsReports(props.match.params.id).then(response => {
                if (response.status === 200) {
                    setReports(response.data);
                }
            });
        }
        setUserData();
        return () => mounted = false;
    }, [props.match.params.id, selectedData]);

    const hasReports = reports.length > 0

    return (
        <React.Fragment>
            <div className='cast-card cast-card--init'>
                {
                    selectedData && courseData && (
                        <div>
                            <div className='cast-form'>
                                <div className='cast-form__title'>
                                    {selectedData.name}
                                    {
                                        selectedData.testCode && (
                                            <div className='cast-form__subtitle'>
                                                Identifier: {selectedData.testCode}
                                            </div>
                                        )
                                    }
                                    {
                                        selectedData.exportedToGoogle && (
                                            <div className='cast-form__labels'>
                                                <Chip
                                                    icon={<CloudDoneIcon />}
                                                    label="Exported to google classroom"
                                                    color="secondary"
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                                <div>
                                    {courseData.map(body => {
                                        if (body.type === 'video') {
                                            return (
                                                <div key={body.id} className='cast-form-box'>
                                                    <TextField value={body.link}
                                                        multiline
                                                        className='cast-form__field' label="Video Link"
                                                        variant="outlined" />
                                                </div>
                                            )
                                        } else if (body.type === 'image') {
                                            return (
                                                <div key={body.id} className='cast-form-box'>
                                                    <TextField value={body.link}
                                                        multiline
                                                        className='cast-form__field' label="Image Link"
                                                        variant="outlined" />
                                                </div>
                                            )
                                        } else if (body.type === 'text') {
                                            return (
                                                <div key={body.id} className='cast-form-box'>
                                                    <TextField value={body.link}
                                                        multiline
                                                        className='cast-form__field' label="Text"
                                                        variant="outlined" />
                                                </div>
                                            )
                                        } else if (body.type.toLowerCase() === 't/f' || body.type.toLowerCase() === 'ut/f') {
                                            return (
                                                <div key={body.id} className='cast-form-box'>
                                                    <div className='cast-form__field'>
                                                        <TextField value={body.question}
                                                            multiline
                                                            label={body.type.toLowerCase() === 't/f' ? 'True/False Question' : 'Ungraded True/False Question'}
                                                            variant="outlined" />
                                                        {body.type.toLowerCase() === 't/f' ?
                                                            <RadioGroup className='cast-form__radio-group' aria-label="gender"
                                                                name="gender1" value={body.booleanAnswer.toString()}>
                                                                <div>Answer:</div>
                                                                <FormControlLabel value="true" control={<Radio color="primary" />}
                                                                    label="True" />
                                                                <FormControlLabel value="false" control={<Radio color="primary" />}
                                                                    label="False" />
                                                            </RadioGroup>
                                                        : null }
                                                    </div>
                                                    {body.hasFeedback ?
                                                        body.type.toLowerCase() === 't/f' ?
                                                            <div className='cast-form__feedback'>
                                                                <div className='cast-form__feedback-box'>
                                                                    <TextField
                                                                        className='cast-form__feedback-message'
                                                                        multiline
                                                                        label="Feedback For Correct Answer"
                                                                        value={body.feedbackMessageForCorrectBooleanAnswer}
                                                                        variant="outlined"
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <AddCommentIcon />
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        label="Feedback For Incorrect Answer"
                                                                        multiline
                                                                        value={body.feedbackMessageForWrongBooleanAnswer}
                                                                        variant="outlined"
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <AddCommentIcon />
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        :
                                                            <div className='cast-form__feedback'>
                                                                <div className='cast-form__feedback-box'>
                                                                    <TextField
                                                                        className='cast-form__feedback-message'
                                                                        multiline
                                                                        label="Add Feedback"
                                                                        value={body.feedbackMessage}
                                                                        variant="outlined"
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <AddCommentIcon />
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                    : null }
                                                </div>
                                            )
                                        } else if (body.type.toLowerCase() === 'mc' || body.type.toLowerCase() === 'umc') {
                                            return (
                                                <div key={body.id} className='cast-form-box'>
                                                    <div className='cast-form__field'>
                                                        <TextField value={body.question}
                                                            multiline
                                                            className='cast-form__field'
                                                            label={body.type.toLowerCase() === 'mc' ? 'Multiple Choice Question' : 'Ungraded Multiple Choice'}
                                                            variant='outlined'
                                                             />
                                                        {body.multipleAnswerList.map(multipleQuestion => {
                                                            if (multipleQuestion.answerText) {
                                                                return (
                                                                    <div key={multipleQuestion.key} className='cast-form__box'>
                                                                        {body.type.toLowerCase() === 'mc' ? (
                                                                            <Checkbox checked={multipleQuestion.answer}
                                                                            className='cast-form__checkbox'
                                                                            inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                                                                            color="primary" />
                                                                        ) : null }
                                                                        <TextField value={multipleQuestion.answerText}
                                                                            multiline
                                                                            className='cast-form__field' label="Answer"
                                                                            variant="outlined" />
                                                                        {multipleQuestion.hasFeedback ? (
                                                                            <div className='cast-form__feedback'>
                                                                                <div className='cast-form__feedback-box'>
                                                                                    <TextField
                                                                                        className='cast-form__feedback-message'
                                                                                        multiline
                                                                                        label="Add Feedback"
                                                                                        value={multipleQuestion.feedbackMessage}
                                                                                        variant="outlined"
                                                                                        InputProps={{
                                                                                            startAdornment: (
                                                                                                <InputAdornment position="start">
                                                                                                    <AddCommentIcon />
                                                                                                </InputAdornment>
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ) : null }
                                                                    </div>
                                                                )
                                                            } else {
                                                                return null
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        } else if (body.type.toLowerCase() === 'ff' || body.type.toLowerCase() === 'uff') {
                                            return (
                                                <div key={body.id} className='cast-form-box'>
                                                    <TextField value={body.question}
                                                        multiline
                                                        className='cast-form__field'
                                                        label='Open Ended Question'
                                                        variant="outlined" />
                                                    {body.hasFeedback ? (
                                                        <div className='cast-form__feedback'>
                                                            <div className='cast-form__feedback-box'>
                                                                <TextField
                                                                    className='cast-form__feedback-message'
                                                                    multiline
                                                                    label="Add Feedback"
                                                                    value={body.feedbackMessage}
                                                                    variant="outlined"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <AddCommentIcon />
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : null }
                                                </div>
                                            )
                                        } else if (body.type.toLowerCase() === 'uls') {
                                            return (
                                                <div key={body.id} className='cast-form-box'>
                                                    <div className='cast-form__field'>
                                                        <TextField value={body.question}
                                                            multiline
                                                            className='cast-form__field'
                                                            label='Likert Scale'
                                                            variant="outlined"
                                                        />
                                                        <div className='likert_scale_selected_answers'>
                                                            {body.multipleAnswerList.map((answer, idx) => {
                                                                if (answer.answerText) {
                                                                    return (
                                                                        <div key={idx} className='cast-form__box'>
                                                                            <div className='cast-form__box-answer'>
                                                                            <TextField value={answer.answerText}
                                                                                    multiline
                                                                                    className='cast-form__field likert-scale-answers' label="Answer"
                                                                                    variant="outlined"
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                } else return null;
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (body.type.toLowerCase() === 'urs') {
                                            return (
                                                <div key={body.id} className='cast-form-box'>
                                                    <div className='cast-form__field'>
                                                        <div className='cast-form__rating-scale'>
                                                            <span>On a scale of 1 to {body.answersAmount} where 1 is {body.multipleAnswerList[0].answerText} and {body.answersAmount} is {body.multipleAnswerList[body.answersAmount - 1].answerText}</span>
                                                        </div>
                                                        <TextField value={body.question}
                                                            multiline
                                                            className='cast-form__field'
                                                            label='Rating Scale question'
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        } else return null;
                                    })}
                                </div>
                            </div>
                            <div className='cast-card__action-group'>
                                {hasReports ? (
                                    <Tooltip placement="top" title="You cannot edit this activity because it has reports">
                                        <div className='cast-card__action'>
                                            <Button disabled onClick={() => editCourse(selectedData.id)}
                                                variant="contained" color="primary">
                                                Edit
                                            </Button>
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Button className='cast-card__action' onClick={() => editCourse(selectedData.id)}
                                        variant="contained" color="primary">
                                        Edit
                                    </Button>
                                )}
                                {selectedData.status.toLowerCase() === 'published' ? (
                                    <div className='cast-card__action'>
                                        <UnpublishConfirmationComponent
                                            unpublishTest={unpublishCourse} />
                                    </div>
                                ) : null}
                                {
                                    !selectedData.exportedToGoogle && hasGoogleAuthorizationTokens && (
                                        <div className='cast-card__action'>
                                            <ExportConfirmationComponent
                                                exportToGoogleClassroom={handleExportToGoogleClassroom}
                                                selectedData={selectedData} />
                                        </div>
                                    )
                                }
                                <Link className='cast-card__action' to={'/app/tests'}>
                                    <Button variant="contained">
                                        Back
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    )
                }
            </div>
        </React.Fragment>
    );
}