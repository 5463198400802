import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from "../../login-logo.jpg";
import API from '../../api';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#fff',
        width: 84,
        height: 50,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    primary: {
        main: '#284358',
    },
    link: {
        color: '#3f51b5'
    },
    logo: {
        width: '200px',
        marginBottom: '20px'
    }
}));

export default function RegistrationComponent(props) {
    const recaptchaRef = React.createRef();

    let [person, setPerson] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        passwordCheck: ''
    });

    let [validation, setValidation] = useState({
        firstname: false,
        lastname: false,
        email: false,
        password: false,
        passwordMismatch: false,
        passwordStructure: false
    })

    let [recaptchaError, setRecaptchaError] = useState('');

    const classes = useStyles();

    const updatePersonFirstName = (e) => {
        person.firstname = e.target.value
        setPerson({ ...person });
    }

    const updatePersonLastName = (e) => {
        person.lastname = e.target.value
        setPerson({ ...person });
    }

    const updatePersonEmail = (e) => {
        person.email = e.target.value
        setPerson({ ...person });
    }

    const updatePersonPassword = (e) => {
        person.password = e.target.value
        setPerson({ ...person });
    }

    const updatePersonPasswordCheck = (e) => {
        person.passwordCheck = e.target.value
        setPerson({ ...person });
    }

    const validateEmail = (email) => {
        //eslint-disable-next-line
        const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !regExp.test(String(email).toLowerCase());
    }

    const validatePassword = (password) => {
        //eslint-disable-next-line
        const regExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&"^()])[A-Za-z\d@$!%*#?&"^()]{8,}$/;
        return !regExp.test(String(password));
    }

    const registerHandler = (e) => {
        e.preventDefault();

        for (const [key, value] of Object.entries(person)) {
            if (!value) {
                validation[key] = true;
            }

            if (value) {
                validation[key] = false;
            }

            if (key === 'email') {
                validation[key] = validateEmail(value);
            }

            if (key === 'password') {
                validation[key] = !(person.password === person.passwordCheck) || validatePassword(person.password);
                validation['passwordMismatch'] = !(person.password === person.passwordCheck);
                validation['passwordStructure'] = validatePassword(person.password)
            }
        }
        setValidation({ ...validation });

        if (!validation.firstname && !validation.lastname && !validation.email && !validation.password) {
            const recaptchaValue = recaptchaRef.current.getValue();
            if (!recaptchaValue) {
                setRecaptchaError('Please verify you are a human.');
            } else {
                setRecaptchaError('');
                person.recaptcha = recaptchaValue;
                API.Auth.signUp(person)
                    .then(response => {
                        if (response.status === 200) {
                            sessionStorage.setItem("access_token", response.data.access_token);
                            sessionStorage.setItem("user_type", response.data.user_type);
                            sessionStorage.setItem("user_tier", response.data.user_tier);
                            sessionStorage.setItem("google_authorization_url", response.data.google_authorization_url);
                            sessionStorage.setItem("has_google_authorization_tokens", response.data.has_google_authorization_tokens);
                            props.login(props, response.data.userID);
                            props.error(false);
                        }
                    })
                    .catch(error => {
                        props.error(error.response.data.message);
                        console.log(error);
                    });
            }
        }
    }

    const errorMessage = props.showError && (
        <Typography component="h6" align='center' variant="h6" style={{ color: "red" }}>
            {props.showError}
        </Typography>)

    return (
        <Container component="main" maxWidth="xs" className={classes.palette}>
            <CssBaseline />
            <div className={classes.paper}>
                <img src={logo} className={classes.logo} alt="Logo" />
                <Avatar className={classes.avatar}>
                    <svg width={80} height={50} viewBox="0 0 32 28" fill='#284358'>
                        <path
                            d="M11 14c-3.313 0-6-2.688-6-6s2.688-6 6-6 6 2.688 6 6-2.688 6-6 6zM26 16h5.5c0.266 0 0.5 0.234 0.5 0.5v3c0 0.266-0.234 0.5-0.5 0.5h-5.5v5.5c0 0.266-0.234 0.5-0.5 0.5h-3c-0.266 0-0.5-0.234-0.5-0.5v-5.5h-5.5c-0.266 0-0.5-0.234-0.5-0.5v-3c0-0.266 0.234-0.5 0.5-0.5h5.5v-5.5c0-0.266 0.234-0.5 0.5-0.5h3c0.266 0 0.5 0.234 0.5 0.5v5.5zM14.5 19.5c0 1.094 0.906 2 2 2h4v3.719c-0.766 0.562-1.734 0.781-2.672 0.781h-13.656c-2.5 0-4.172-1.5-4.172-4.047 0-3.531 0.828-8.953 5.406-8.953 0.25 0 0.422 0.109 0.609 0.266 1.531 1.172 3.016 1.906 4.984 1.906s3.453-0.734 4.984-1.906c0.187-0.156 0.359-0.266 0.609-0.266 1.328 0 2.5 0.5 3.391 1.5h-3.484c-1.094 0-2 0.906-2 2v3z" />
                    </svg>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <form className={classes.form}>
                    {errorMessage}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="off"
                                name="firstName"
                                error={validation.firstname}
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                label="First Name"
                                value={person.firstname}
                                onChange={(e) => updatePersonFirstName(e)}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                margin="normal"
                                fullWidth
                                error={validation.lastname}
                                label="Last Name"
                                name="lastName"
                                value={person.lastname}
                                onChange={(e) => updatePersonLastName(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                type='email'
                                label="Email Address"
                                name="email"
                                error={validation.email}
                                helperText={validation.email ? 'Invalid email' : ''}
                                value={person.email}
                                onChange={(e) => updatePersonEmail(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                error={validation.password}
                                label="Password"
                                type="password"
                                value={person.password}
                                onChange={(e) => updatePersonPassword(e)}
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                error={validation.password}
                                helperText={validation.passwordMismatch ? 'Password mismatch' : ''}
                                label="Confirm Password"
                                type="password"
                                value={person.passwordCheck}
                                onChange={(e) => updatePersonPasswordCheck(e)}
                                autoComplete="new-password"
                            />
                            <Typography variant="caption" color={validation.passwordStructure ? 'error' : 'primary'}>
                                The password must be at least 8 characters long. It must contain at least one number and one special character (@$!%*#?&"^).
                            </Typography>
                        </Grid>
                        <Grid item xs={12} align="center">
                            <Typography component="p" align='center' variant="body1" style={{ color: 'red' }}>
                                {recaptchaError}
                            </Typography>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LcRnr8aAAAAAEkjTmYAz-yrJf83G9vEtvtv-djI'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={(e) => registerHandler(e)}
                        className={classes.submit}
                    >
                        Sign Up
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link className={classes.link} href="/login" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}