import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));


export default function UnpublishConfirmationComponent(props) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleIsLoading = () => {
        setIsLoading(true)
    }

    const handleIsNotLoading = () => {
        setIsLoading(false)
    }

    const handleUnpublishTest = async () => {
        handleIsLoading();
        await props.unpublishTest()
        handleIsNotLoading();
        handleClose();
    };

    const classes = useStyles();

    return (
        <div>
            <Button variant="contained" color="primary" onClick={() => handleClickOpen()}>
                Unpublish
            </Button>
            <Dialog open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm">
                <DialogTitle id="alert-dialog-title">Unpublish Activity</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Unpublishing an activity will set it back to draft and it will not be available to your students until you publish it again.
                    </DialogContentText>
                    <DialogContentText>
                        Do you want to unpublish this activity?
                    </DialogContentText>
                </DialogContent>
                {
                    !isLoading ?
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleUnpublishTest} color="primary" autoFocus>
                                Unpublish
                            </Button>
                        </DialogActions>
                        :
                        <div className={classes.root}>
                            <LinearProgress />
                        </div>
                }
            </Dialog>
        </div>
    );
}