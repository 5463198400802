import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, TextField, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import { Bar } from 'react-chartjs-2';
import API from '../../api';


export function AnswerIcon(props) {
    const { icon } = props;
    if (icon === 'correct') {
        return (
            <CheckIcon className='cast-form__correct_answer'/>
        )
    } else if (icon === 'hidden') {
        return (
            <CheckIcon className='cast-form__hidden_answer'/>
        )
    }
}

export default function StudentDetailComponent(props) {
    const [testData, setTestData] = React.useState();
    const [reportData, setReportData] = React.useState();
    const [chartData, setChartData] = React.useState();
    const [chartOptions, setChartOptions] = React.useState();

    const setBarChart = (questionData) => {
        const data = {}
        data.labels = [];
        data.datasets = [{
            label: '% Correct Answers',
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 0.5,
            barPercentage: 0.5
        }];

        const options = {
            indexAxis: 'y',
            scales: {
                x: {
                    min: 0,
                    max: 100
                }
            },
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
            },
            maintainAspectRatio: false,
        };

        questionData.forEach((question, idx) => {
            data.labels.push(`Question ${idx + 1}`);
            data.datasets[0].data.push(question.correctPercentage * 100);
            data.datasets[0].backgroundColor.push(question.barColor);
            data.datasets[0].borderColor.push('rgba(0, 0, 0, 1)',)
        });

        setChartData(data);
        setChartOptions(options);
    }

    const evaluateAnswers = (test, report) => {
        let questionData = test.questions.filter(
            body => ['t/f', 'mc', 'ff'].includes(body.type.toLowerCase()));
        questionData = questionData.map(v => ({
            ...v,
            correctTotal: 0,
            incorrectTotal: 0
        }));
        const reportData = report.filter(report => report.studentAnswers.length > 0);
        questionData.forEach((question, questionNumber)  => {
            if (question.type === 'mc') {
                question.multipleAnswerList = question.multipleAnswerList.map(v => ({
                    ...v,
                    total: 0
                }));
                question.multipleAnswerList.forEach((answer, answerNumber) => {
                    // Indicate the correct answers, hide the others
                    if (answer.answer) {
                        answer.icon = 'correct';
                    } else {
                        answer.icon = 'hidden';
                    }
                    // Add to the answer total amount if the student picked it
                    reportData.forEach(report => {
                        if(report.studentAnswers[questionNumber] && report.studentAnswers[questionNumber].studentResponse.includes((answerNumber + 1).toString())) {
                            answer.total++;
                        }
                    });
                });
            } else if (question.type === 't/f') {
                question.answers = [{answerText: 'True', total: 0},{answerText: 'False', total: 0}];
                question.answers.forEach((answer, answerNumber) => {
                    // Indicate the correct answers, hide the others
                    if ((answerNumber === 0 && question.booleanAnswer) || (answerNumber === 1 && !question.booleanAnswer)) {
                        answer.icon = 'correct';
                    } else {
                        answer.icon = 'hidden';
                    }
                    answer.key = answerNumber;
                    // Add to the answer total amount if the student picked it
                    reportData.forEach(report => {
                        if (report.studentAnswers[questionNumber] && report.studentAnswers[questionNumber].studentResponse[0] === (answerNumber + 1).toString()) {
                            answer.total++;
                        }
                    });
                });
            } else if (question.type === 'ff') {
                question.answers = [
                    {key: 0, answerText: 'Correct', total: 0, icon: 'correct'},
                    {key: 1, answerText: 'Incorrect', total: 0, icon: 'hidden'}
                ];
                // Add to the answer total amount
                reportData.forEach(report => {
                    if (report.score !== -1) {
                        if (report.studentAnswers[questionNumber].responseWasCorrect) {
                            question.answers[0].total++;
                        } else {
                            question.answers[1].total++;
                        }
                    }
                });
            }
            // Count right/wrong answers
            reportData.forEach(report => {
                if (!(report.score === -1 && question.type === 'ff')) {
                    if (report.studentAnswers[questionNumber] && report.studentAnswers[questionNumber].responseWasCorrect) {
                        question.correctTotal++;
                    } else {
                        question.incorrectTotal++;
                    }
                }
            });
            question.correctPercentage = question.correctTotal / reportData.length;
            // Set colors based on grade
            if (question.correctPercentage <= 0.5) {
                question.borderColor = 'cast-form__incorrect-box';
                question.barColor = 'rgba(255, 107, 107, 1)';
            } else if (question.correctPercentage < 0.7) {
                question.borderColor = 'cast-form__average-box';
                question.barColor = 'rgba(255, 221, 0, 1)';
            } else {
                question.borderColor = 'cast-form__correct-box'
                question.barColor = 'rgba(102, 255, 153, 1)';
            }
            // If no answers we remove the border color
            if(question.correctTotal === 0 && question.incorrectTotal === 0) {
                question.borderColor = '';
            }
        });
        setBarChart(questionData);
        setTestData(questionData);
        setReportData(report);
    }

    useEffect(() => {
        let mounted = true;
        if (props.isLoggedIn) {
            API.Tests.getTestsReports(props.match.params.id)
                .then(report => {
                    if (mounted && report.status === 200) {
                        API.Tests.getTests(props.match.params.id)
                            .then(({ data }) => {
                                if (mounted) {
                                    evaluateAnswers(data, report.data);
                                }
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
        return () => mounted = false;
    // TODO - Fix Warning of evaluateAnswers
    // eslint-disable-next-line
    }, [props.isLoggedIn, props.match.params.id]);

    return (
        <div className='cast-card cast-card--init'>
            {
                testData && reportData && (
                    <div className='cast-form'>
                        <div className='cast-form__section-report'>
                            <div className='cast-form__title'>
                                Activity Performance
                            </div>
                            <Link className='cast-toolbar__action' to={`/app/report/${props.match.params.id}`} exact="true">
                                <Button variant="contained">
                                    Back
                                </Button>
                            </Link>
                        </div>
                        { chartData && chartOptions && reportData.length > 0 && (
                            <div>
                                <div className='cast-form__performance-title'>Correct/Incorrect</div>
                                <div className='cast-form__performance-container'>
                                    <div className='cast-form__performance-graph'>
                                        <Bar data={chartData} options={chartOptions} />
                                    </div>
                                    <div className='cast-form__performance-table'>
                                        <Table>
                                            <TableBody>
                                                { testData.map((question, idx) => {
                                                    return (
                                                        <TableRow key={idx}>
                                                            <TableCell align="center">{`${question.correctTotal}/${question.incorrectTotal}`}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div>
                            {testData.map((body, idx) => {
                                if (body.type.toLowerCase() === 't/f') {
                                    return (
                                        <div key={body.id} className={`cast-form-box ${body.borderColor}`}>
                                            <div className='cast-form__field'>
                                                <TextField value={body.question}
                                                    multiline
                                                    className='cast-form__field'
                                                    label={`Question ${idx+1}`}
                                                    variant="outlined" />
                                                {body.answers.map(answer => {
                                                    return (
                                                        <div key={answer.key} className='cast-form__box'>
                                                            <div className='cast-form__answer_total'>{answer.total}</div>
                                                            <AnswerIcon icon={answer.icon} />
                                                            <TextField value={answer.answerText}
                                                                multiline
                                                                className='cast-form__field' label="Answer"
                                                                variant="outlined" />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                } else if (body.type.toLowerCase() === 'mc') {
                                    return (
                                        <div key={body.id} className={`cast-form-box ${body.borderColor}`}>
                                            <div className='cast-form__field'>
                                                <TextField value={body.question}
                                                    multiline
                                                    className='cast-form__field'
                                                    label={`Question ${idx+1}`}
                                                    variant="outlined" />
                                                {body.multipleAnswerList.map((multipleQuestion, idx) => {
                                                    if (multipleQuestion.answerText) {
                                                        return (
                                                            <div key={multipleQuestion.key} className='cast-form__box'>
                                                                <div className='cast-form__answer_total'>{multipleQuestion.total}</div>
                                                                <AnswerIcon icon={multipleQuestion.icon} />
                                                                <TextField value={multipleQuestion.answerText}
                                                                    multiline
                                                                    className='cast-form__field' label={String.fromCharCode(65 + idx)}
                                                                    variant="outlined" />
                                                            </div>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    )
                                } if (body.type.toLowerCase() === 'ff') {
                                    return (
                                        <div key={body.id} className={`cast-form-box ${body.borderColor}`}>
                                            <div className='cast-form__field'>
                                                <TextField value={body.question}
                                                    multiline
                                                    className='cast-form__field'
                                                    label={`Question ${idx+1}`}
                                                    variant="outlined" />
                                                {body.answers.map(answer => {
                                                    return (
                                                        <div key={answer.key} className='cast-form__box'>
                                                            <div className='cast-form__answer_total'>{answer.total}</div>
                                                            <AnswerIcon icon={answer.icon} />
                                                            <TextField value={answer.answerText}
                                                                multiline
                                                                className='cast-form__field' label="Answer"
                                                                variant="outlined" />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                } else return null;
                            })}
                        </div>
                    </div>
                )
            }
        </div>
    )
}