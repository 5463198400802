export const getTestsRoute = (id) => id ? `/tests/${id}` : `/tests`;

export const getExportToGoogleClassroomRoute = (id) => `${getTestsRoute(id)}/export/google_classroom`;

export const getTestsDraftRoute = () => `${getTestsRoute()}/draft`;

export const getTestsExportRoute = () => `${getTestsRoute()}/export`;

export const getTestsImportRoute = () => `${getTestsRoute()}/import`;

export const getTestsReportsRoute = (id) => `${getTestsRoute(id)}/reports`;

export const getTestsReportSummaryRoute = (id) => `${getTestsRoute(id)}/reports/summary`;

export const getUnpublishTestRoute = (id) => `${getTestsRoute(id)}/unpublish`;