import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../login-logo.jpg'

import API from '../../api';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        textTransform: 'capitalize',
        fontSize: '18px'
    },
    link: {
        color: '#3f51b5'
    },
    logo: {
        width: '200px',
        marginBottom: '20px'
    }
}));


export default function LoginComponent(props) {
    const recaptchaRef = React.createRef();

    let [person, setPerson] = useState({
        email: '',
        password: ''
    });

    let [recaptchaError, setRecaptchaError] = useState('');

    const classes = useStyles();

    const updatePersonEmail = (e) => {
        person.email = e.target.value

        setPerson({ ...person });
    }

    const updatePersonPassword = (e) => {
        person.password = e.target.value;
        setPerson({ ...person });
    }

    const loginHandler = (e) => {
        e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            setRecaptchaError('Please verify you are a human.');
        } else {
            setRecaptchaError('');
            person.recaptcha = recaptchaValue;
            API.Auth.signIn(person)
                .then(response => {
                    if (response.status === 200 && response.data.access_token) {
                        sessionStorage.setItem("access_token", response.data.access_token);
                        sessionStorage.setItem("user_type", response.data.user_type);
                        sessionStorage.setItem("user_tier", response.data.user_tier);
                        sessionStorage.setItem("google_authorization_url", response.data.google_authorization_url);
                        sessionStorage.setItem("has_google_authorization_tokens", response.data.has_google_authorization_tokens);
                        props.login(props, response.data.access_token);
                        props.error(false);
                    }
                    else {
                        throw new Error("Not valid.");
                    }
                })
                .catch(error => {
                    if (error.response.data.message === 'Invalid reCAPTCHA') {
                        props.error('Invalid reCAPTCHA');
                    } else {
                        props.error('Incorrect username or password.');
                    }
                    console.log(error);
                });
        }
    }

    const errorMessage = props.showLoginError && (
        <Typography component="h6" align='center' variant="h6" style={{ color: "red" }}>
            {props.showLoginError}
        </Typography>)

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={logo} className={classes.logo} alt="Logo" />
                <Typography component="h1" variant="h5">
                    Log In
                </Typography>
                <form className={classes.form} noValidate>
                    {errorMessage}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        value={person.email}
                        onChange={(e) => updatePersonEmail(e)}
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={person.password}
                        onChange={(e) => updatePersonPassword(e)}
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Grid item xs={12} align="center">
                        <Typography component="p" align='center' variant="body1" style={{ color: 'red' }}>
                            {recaptchaError}
                        </Typography>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LcRnr8aAAAAAEkjTmYAz-yrJf83G9vEtvtv-djI'}
                        />
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={(e) => loginHandler(e)}
                        className={classes.submit}
                    >
                        Log In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link className={classes.link} href="/recovery" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link className={classes.link} href="/registration" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}