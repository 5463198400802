import Client from '../../services/client';
import {
    getAuthSignInRoute,
    getAuthSignOutRoute,
    getAuthSignUpRoute,
    getGoogleAuthTokensRoute,
    getPasswordResetRequestRoute,
    getPasswordResetRoute,
    getResetStatusCheckRoute,
} from './routes';

export const getGoogleAuthTokens = () => Client.get(getGoogleAuthTokensRoute());

export const getResetStatusCheck = (identification) => Client.get(getResetStatusCheckRoute(), {
    params: {
        passwordResetToken: identification,
    }
});

export const requestPasswordReset = (body) => Client.post(getPasswordResetRequestRoute(), body);

export const resetPassword = (body) => Client.post(getPasswordResetRoute(), body);

export const signIn = (body) => Client.post(getAuthSignInRoute(), body);

export const signOut = () => Client.get(getAuthSignOutRoute());

export const signUp = (body) => Client.post(getAuthSignUpRoute(), body);
