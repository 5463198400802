import React, {useEffect, useState} from 'react';

import queryString from 'query-string';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../../login-logo.jpg'
import API from '../../../api';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        width: '200px',
        marginBottom: '20px'
    }
}));


export default function ResetPasswordComponent(props) {
    const classes = useStyles();
    let isValid = false;

    const [person, setPerson] = useState({
        password: '',
        passwordCheck: ''
    });
    const [validation, setValidation] = useState(false);
    const [passwordSendError, setPasswordSendError] = useState(false);
    const [passwordMismatchError, setPasswordMismatchError] = useState(false);
    const [passwordStructureError, setPasswordStructureError] = useState(false);
    const [validLink, setValidationForLink] = useState(true);


    const [hasRendered, setHasRendered] = useState(false)
    useEffect(() => setHasRendered(true), [hasRendered])
    if (!hasRendered) {
        const identification = queryString.parse(props.location.search).passwordResetToken;
        API.Auth.getResetStatusCheck(identification)
            .then(response => {
                if (response.status === 200) {
                    setValidationForLink(false);
                }
            })
            .catch(error => {

            });
    }

    const updatePersonPassword = (e) => {
        person.password = e.target.value;
        isValid = false;
        if (validation) setValidation(false);
        setPerson({...person});
    }

    const updatePersonPasswordCheck = (e) => {
        person.passwordCheck = e.target.value;
        isValid = false;
        if (validation) setValidation(false);
        setPerson({...person});
    }

    const validatePassword = (password) => {
        const regExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&"^()])[A-Za-z\d@$!%*#?&"^()]{8,}$/;
        return !regExp.test(String(password));
    }

    const loginHandler = (e) => {
        e.preventDefault();

        if (person.password !== person.passwordCheck || validatePassword(person.password)) {
            validatePassword(person.password) ? setPasswordStructureError(true) : setPasswordStructureError(false);
            person.password !== person.passwordCheck ? setPasswordMismatchError(true) : setPasswordMismatchError(false);
            isValid = false;
            setValidation(true);
        } else {
            isValid = true;
        }

        if (isValid) {
            API.Auth.resetPassword({
                password: person.password,
                passwordResetToken: queryString.parse(props.location.search).passwordResetToken
            })
                .then(response => {
                    if (response.status === 200) {
                        props.history.push('/login');
                    }
                })
                .catch(error => {
                    isValid = false;
                    setPasswordSendError(true);
                });
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <img src={logo} className={classes.logo} alt="Logo" />
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                {!isValid && passwordSendError && (
                    <Typography component="h2" align='center' variant="h6" style={{color: "red"}}>
                        Something went wrong. Please try again
                    </Typography>
                )}
                {validLink ? (
                    <Typography component="h2" align='center' variant="h6" style={{color: "red"}}>
                        Invalid reset link.
                    </Typography>
                ) : (
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            value={person.password}
                            error={validation}
                            onChange={(e) => updatePersonPassword(e)}
                            autoComplete="new-password"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Confirm Password"
                            type="password"
                            value={person.passwordCheck}
                            error={validation}
                            helperText={passwordMismatchError ? 'Password mismatch' : ''}
                            onChange={(e) => updatePersonPasswordCheck(e)}
                            autoComplete="new-password"
                        />
                        <Typography variant="caption" color={passwordStructureError ? 'error' : 'primary'}>
                            The password must be at least 8 characters long. It must contain at least one number and one special character (@$!%*#?&"^).
                        </Typography>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={(e) => loginHandler(e)}
                            className={classes.submit}>
                            Change
                        </Button>
                    </form>
                )}
            </div>
        </Container>
    );
}
