import React, { useEffect } from 'react';
import orderBy from "lodash/orderBy";
import './main.component.scss';
import { Link, NavLink, Redirect, Route, Switch } from 'react-router-dom';
import logo from '../../login-logo.jpg';
import botImage from '../../bot.png';
import StudentReportComponent from "../../components/report/student-report.component";
import StudentDetailComponent from "../../components/report/student-detail.component";
import PerformanceDetailComponent from "../../components/report/performance-detail.component";
import ReportDashboardComponent from '../../components/report/report-dashboard.component';
import NewTestComponent from "../../components/test/new-test.component";
import TestTableComponent from "../../components/test/test-table.component";
import TestComponent from "../../components/test/test.component";
import ProfileComponent from "../../components/user/profile.component";
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LoadingModalComponent from "../../components/loading-modal/loading-modal.component";

import API from '../../api';
import SurveyActivityDashboardDetails from '../../components/report/survey-activity-dashboard-details';

const invertDirection = {
    asc: "desc",
    desc: "asc"
};

function objectIsNotEmpty(obj){
    return value => Object.keys(value).length !== 0;
}

export default function Main(props) {
    const [rows, setRows] = React.useState([]);
    const [filteredRows, setFilteredRows] = React.useState([]);
    const [columnToSort, setColumnToSort] = React.useState('');
    const [sortDirection, setSortDirection] = React.useState('asc');
    const [searchInputValue, setSearchInputValue] = React.useState('');
    const [searchIsOpen, setSearchIsOpen] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(props.loginStatus);
    const [initialized, setInitialized] = React.useState(false);
    const [deleting, setDeleting] = React.useState(false);
    const [loadingModalTitle, setLoadingModalTitle] = React.useState('');
    const [loadingModalDescription, setLoadingModalDescription] = React.useState('');

    const handleTestSave = async (history, id) => {
        await setUpdatedTestData();
        history.push(`/app/tests/${id}`);
    }

    const handleTestUpdate = async (history, id, data, exportedToGoogle) => {
        try {
            if (exportedToGoogle) {
                setDeleting(true);
                setLoadingModalTitle('Updating a test');
                setLoadingModalDescription('We are updating a test that was already exported to google classroom. This will also update the test in google classroom.');
            }
            await API.Tests.updateTest(id, data);
            await handleTestSave(history, id);
            setDeleting(false);
            setLoadingModalTitle('');
            setLoadingModalDescription('');
        } catch (error) {
            console.log(error);
        }
    }

    const handleTestCreate = async (history, data) => {
        try {
            const { data: { id } } = await API.Tests.createTest(data);
            await handleTestSave(history, id);
        } catch (error) {
            console.log(error);
        }
    }

    const loadingModalOnClose = async () => {
        setDeleting(true);
    }

    const handleTestDelete = async (id, exportedToGoogle) => {
        try {
            if (exportedToGoogle) {
                setDeleting(true);
                setLoadingModalTitle('Deleting a test');
                setLoadingModalDescription('We are deleting an activity that was already exported to google classroom. This will also delete the activity in google classroom.');
            }
            await API.Tests.deleteTest(id);
            await setUpdatedTestData();
            setDeleting(false);
            setLoadingModalTitle('');
            setLoadingModalDescription('');
            props.history.push(`/app/tests/`);
        } catch (error) {
            console.log(error);
        }
    }

    const handleTestDuplicate = async (id) => {
        try {
            const { data: { name, questions } } = await API.Tests.getTests(id);
            await API.Tests.createTest({ name, questions });
            await setUpdatedTestData();
            props.history.push(`/app/tests/`);
        } catch (error) {
            console.log(error);
        }
    }

    const handleUnpublishTest = async (id) => {
        try {
            await API.Tests.unpublishTest(id);
            await setUpdatedTestData();
            props.history.push(`/app/tests/`);
        } catch (error) {
            console.log(error);
        }
    }

    const handleExportToGoogleClassroom = (id, enrollmentCode) => {
        return API.Tests.exportToGoogleClassroom(id, { enrollment_code: enrollmentCode });
    }

    const handleSort = (columnName) => {
        setColumnToSort(columnName);
        setSortDirection(columnToSort === columnName
            ? invertDirection[sortDirection]
            : "asc"
        );
    };

    const triggerLogOut = () => {
        API.Auth.signOut()
            .then(response => {
                if (response.status === 200) {
                    props.logOut(props);
                    sessionStorage.clear();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleSearchToggle = (isOpen) => {
        setSearchIsOpen(isOpen);
        if (!isOpen) {
            setSearchInputValue('');
            setFilteredRows(rows);
        }
    }

    const handleSearchValueChange = (value) => {
        setSearchInputValue(value);
        setFilteredRows(rows.filter(option => option.name.trim().toLowerCase().includes(value.toLowerCase())));
    }

    const setUpdatedTestData = async () => {
        try {
            const { status, data } = await API.Tests.getTests();
            if (status === 200) {
                setRows(data);
                setFilteredRows(data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const formatCourseReq = (data) => {
        const courseData = {
            name: data.name,
            questions: data.course.courseData,
            order_id: data.id
        };

        if (data.status === 'published') {
            courseData.published = true;
        } else {
            courseData.published = false;
        }

        return courseData;
    }

    useEffect(() => {
        let mounted = true;
        if (isLoggedIn) {
            let courseData;
            API.Tests.getTests()
                .then(response => {
                    if (mounted) {
                        if (response.status === 200) {
                            courseData = response.data;
                            setRows(courseData);
                            setIsLoggedIn(true);
                            setFilteredRows(courseData.filter(row => objectIsNotEmpty(row)));
                            setInitialized(true);
                        } else {
                            setIsLoggedIn(false);
                            setInitialized(false);
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
        return () => mounted = false;
    }, [isLoggedIn]);

    const navBar = (
        <React.Fragment>
            <a className='cast-header__action' target="_blank" rel="noopener noreferrer" href="https://support.helloplato.com/portal/en/home">
                    <Button>Support</Button>
            </a>
            <NavLink className='cast-header__action' to={'/app/profile'} exact={true}>
                <Button>Profile</Button>
            </NavLink>
            {
                sessionStorage.getItem('has_google_authorization_tokens') === 'true' ?
                    ''
                    :
                    <a className='cast-header__action' target="_blank" rel="noopener noreferrer" href={sessionStorage.getItem('google_authorization_url')}>
                        <Button>Authorize w/google</Button>
                    </a>
            }
            <NavLink className='cast-header__action' to={'/app/tests'} exact={true}>
                <Button>Dashboard</Button>
            </NavLink>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <div className='cast-container'>
                <div className='cast-header'>
                    <div className='cast-header__section'>
                        <Link to={'/app/tests'} style={{ 'width': 'fit-content' }}>
                            <img src={logo} className='cast-header__icon' alt="Logo" />
                        </Link>
                    </div>
                    <div className='cast-header__section'>
                        <a className='cast-header__link' href=" https://api.whatsapp.com/send?phone=15614487076"
                            target="_blank" rel="noopener noreferrer">
                            <span className='cast-header__link-text'>Try Your Activity</span>
                            <img className='cast-header__link-icon' src={botImage} alt="Bot" />
                        </a>
                        <div className='cast-header__nav'>
                            {isLoggedIn ? navBar : null}
                            <NavLink className='cast-header__action' to={'/login'} exact={true}>
                                {isLoggedIn ? <Button onClick={triggerLogOut}>Log Out</Button> :
                                    <Button>Log In</Button>}
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='cast-content'>
                    <Accordion className='cast-ctsr__section'
                        defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>Quick Start Guide</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container
                                justifyContent='center'
                                alignItems='center'
                                spacing={2}>
                                <Grid item md={6} xl={3}>
                                    <Typography align='center' variant='h6'>
                                        Create
                                    </Typography>
                                    <iframe src="https://www.youtube.com/embed/FVpzmarB6uI"
                                        frameBorder='0'
                                        allow='fullscreen; encrypted-media'
                                        title='Create'
                                    />
                                </Grid>
                                <Grid item md={6} xl={3}>
                                    <Typography align='center' variant='h6'>
                                        Try
                                    </Typography>
                                    <iframe src="https://www.youtube.com/embed/fmpEf_qsk-w"
                                        frameBorder='0'
                                        allow='fullscreen; encrypted-media'
                                        title='Try'
                                    />
                                </Grid>
                                <Grid item md={6} xl={3}>
                                    <Typography align='center' variant='h6'>
                                        Share
                                    </Typography>
                                    <iframe src="https://www.youtube.com/embed/KSfkExf2ApQ"
                                        frameBorder='0'
                                        allow='fullscreen; encrypted-media'
                                        title='Share'
                                    />
                                </Grid>
                                <Grid item md={6} xl={3}>
                                    <Typography align='center' variant='h6'>
                                        Review
                                    </Typography>
                                    <iframe src="https://www.youtube.com/embed/DTG1Nr4HNGE"
                                        frameBorder='0'
                                        allow='fullscreen; encrypted-media'
                                        title='Review'
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Switch>
                        <Route path={'/app/tests'} exact={true}
                            render={(props) => <TestTableComponent {...props}
                                initialized={initialized}
                                data={orderBy(filteredRows, columnToSort, sortDirection)}
                                setUpdatedTestData={setUpdatedTestData}
                                formatCourseReq={formatCourseReq}
                                deleteTest={handleTestDelete}
                                duplicateTest={handleTestDuplicate}
                                columnToSort={columnToSort}
                                sortDirection={sortDirection}
                                handleSort={handleSort}
                                isSearchOpen={searchIsOpen}
                                searchInput={searchInputValue}
                                searchToggle={handleSearchToggle}
                                valueChange={handleSearchValueChange} />} />
                        <Route path="/app/tests/add" exact={true}
                            render={(props) => <NewTestComponent {...props} data={rows}
                                testCreate={handleTestCreate}
                                testUpdate={handleTestUpdate} />} />
                        <Route path={'/app/tests/:id'} exact={true}
                            render={(props) => <TestComponent {...props} data={rows}
                                unpublish={handleUnpublishTest}
                                setUpdatedTestData={setUpdatedTestData}
                                exportToGoogleClassroom={handleExportToGoogleClassroom} />} />
                        <Route path={'/app/tests/:id/edit'} exact={true}
                            render={(props) => <NewTestComponent {...props} data={rows}
                                testUpdate={handleTestUpdate} />} />
                        <Route path={'/app/report/:id'} exact={true}
                            render={(props) => <ReportDashboardComponent {...props}
                                isLoggedIn={isLoggedIn} />} />
                        <Route path={'/app/report/:id/:number/details'} exact={true}
                            render={(props) => <SurveyActivityDashboardDetails {...props}
                                isLoggedIn={isLoggedIn} />} />
                        <Route path={'/app/report/:id/students'} exact={true}
                            render={(props) => <StudentReportComponent {...props}
                                isLoggedIn={isLoggedIn} />} />
                        <Route path={'/app/report/:id/student/:student'} exact={true}
                            render={(props) => <StudentDetailComponent {...props}
                                isLoggedIn={isLoggedIn} />} />
                        <Route path={'/app/report/:id/performance'} exact={true}
                            render={(props) => <PerformanceDetailComponent {...props}
                                isLoggedIn={isLoggedIn} />} />
                        <Route path="/app/profile" exact={true}
                            render={(props) => <ProfileComponent {...props} />} />
                        <Redirect from={'/'} to={'/app/tests'} />
                    </Switch>
                </div>
            </div>
            <LoadingModalComponent title={loadingModalTitle} description={loadingModalDescription} open={deleting} onClose={loadingModalOnClose} />
        </React.Fragment>
    );
}