import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));


export default function ExportConfirmationComponent(props) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [modalResponse, setOpenModalResponse] = React.useState(false);
    const [modalResponseMessage, setModalResponseMessage] = React.useState("We have successfully exported the activity into google classroom. We have also invited all the students that were in google classroom to authenticate with google, so that they can start using Hello PLATO and getting grades.")
    const [modalResponseMessageStatus, setModalResponseMessageStatus] = React.useState("success");
    const [enrollmentCode, setEnrollmentCode] = React.useState('');
    const vertical = 'top';
    const horizontal = 'right';
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleIsLoading = () => {
        setIsLoading(true)
    }

    const handleIsNotLoading = () => {
        setIsLoading(false)
    }

    const handleSetOpenModalResponse = () => {
        setOpenModalResponse(true);
    }

    const handleSetCloseModalResponse = () => {
        setOpenModalResponse(false);
        setModalResponseMessageStatus("success");
        setModalResponseMessage("We have successfully exported the activity into google classroom. We have also invited all the students that were in google classroom to authenticate with google, so that they can start using Hello PLATO and getting grades.");
    }

    const handleSetErrorModalResponseMessage = () => {
        setModalResponseMessage("There was an error during the export process, please review that you are using the correct enrollment code and try again. If the issue persist, please contact support.");
    }

    const handleSetModalResponseMessageStatusError = () => {
        setModalResponseMessageStatus("error");
    }

    const handleExportToGoogleClassroom = async () => {
        handleIsLoading();
        try {
            await props.exportToGoogleClassroom(enrollmentCode);
        } catch (error) {
            console.log(error);
            handleSetModalResponseMessageStatusError();
            handleSetErrorModalResponseMessage();
        }
        handleIsNotLoading();
        handleClose();
        handleSetOpenModalResponse();
    };

    const handleEnrollmentCodeExchange = async (e) => {
        setEnrollmentCode(e.target.value);
    }
    const classes = useStyles();

    return (
        <div>
            <Button variant="contained" color="primary" onClick={() => handleClickOpen()}>
                Export to Classroom
            </Button>
            <Dialog open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm">
                <DialogTitle id="alert-dialog-title">Moving the activity to google classroom</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This is a process where we will move the current activity into google classroom. This process requires you to be authenticated with google and will invite all the students that are already in classroom that are not in Hello PLATO.
                    </DialogContentText>
                    <FormControl>
                        <InputLabel htmlFor="my-input">Enrollment Code</InputLabel>
                        <Input
                            id="my-input"
                            aria-describedby="my-helper-text"
                            onChange={handleEnrollmentCodeExchange}
                        />
                        <FormHelperText id="my-helper-text">We only need the enrollment code of the course you want to push this activity to.</FormHelperText>
                    </FormControl>
                </DialogContent>
                {
                    !isLoading ?
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                        </Button>
                            <Button onClick={handleExportToGoogleClassroom} color="primary" autoFocus>
                                Export
                        </Button>
                        </DialogActions>
                        :
                        <div className={classes.root}>
                            <LinearProgress />
                        </div>
                }
            </Dialog>
            {/* <Dialog open={modalResponse}
                onClose={handleSetCloseModalResponse}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Completed the process of exporting a test</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                     {modalResponseMessage} 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSetCloseModalResponse} color="primary" autoFocus>
                        Ok!
                    </Button>
                </DialogActions>
            </Dialog> */}
            <Snackbar open={modalResponse}
                autoHideDuration={6000}
                onClose={handleSetCloseModalResponse}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert severity={modalResponseMessageStatus}>
                    {modalResponseMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}