const likertScaleResponseSet = {
    Frequency: [
        "Never", "Rarely", "Sometimes", "Often", "Always"
    ],
    Quality: [
        "Very Poor", "Poor", "Fair", "Good", "Excellent"
    ],
    Intensity: [
        "None", "Very Mild", "Mild", "Moderate", "Severe"
    ],
    Agreement: [
        "Strongly disagree", "Disagree", "Neither agree nor disagree", "Agree", "Strongly agree"
    ],
    Approval: [
        "Strongly disapprove", "Disapprove", "Neutral", "Approve", "Strongly approve"
    ],
    Awareness:[
        "Not at all aware", "Slightly aware", "Moderately aware", "Very aware", "Extremely aware"
    ],
    Importance: [
        "Not at all important", "Slightly important", "Moderately important", "Very important", "Extremely important"
    ],
    Familiarity: [
        "Not at all familiar", "Slightly familiar", "Moderately familiar", "Very familiar", "Extremely familiar"
    ],
    Satisfaction: [
        "Not at all satisfied", "Slightly satisfied", "Moderately satisfied", "Very satisfied", "Completely satisfied"
    ],
    Performance: [
        "Far below standards", "Below standards", "Meets standards", "Above standards", "Far above standards"
    ]
}

export default likertScaleResponseSet;