import Client from '../../services/client';
import {
    getExportToGoogleClassroomRoute,
    getTestsDraftRoute,
    getTestsExportRoute,
    getTestsImportRoute,
    getTestsReportsRoute,
    getTestsReportSummaryRoute,
    getTestsRoute,
    getUnpublishTestRoute,
} from './routes';

export const createTest = (data) => Client.post(getTestsRoute(), data);

export const deleteTest = (id) => Client.delete(getTestsRoute(id));

export const draftTest = (draft) => Client.post(getTestsDraftRoute(), draft);

export const exportTests = (body, config) => Client.post(getTestsExportRoute(), body, config);

export const exportToGoogleClassroom = (id, body) => Client.post(getExportToGoogleClassroomRoute(id), body);

export const getTests = (id) => Client.get(getTestsRoute(id));

export const getTestsReports = (id) => Client.get(getTestsReportsRoute(id));

export const getTestsReportSummary = (id) => Client.get(getTestsReportSummaryRoute(id));

export const importTests = (tests) => Client.post(getTestsImportRoute(), tests);

export const unpublishTest = (id) => Client.post(getUnpublishTestRoute(id));

export const updateTest = (id, body) => Client.put(getTestsRoute(id), body);
