import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Main from './containers/main/main.component'
import './App.scss';
import LoginComponent from "./containers/login/login.component";
import RegistrationComponent from "./containers/login/registration.component";
import PasswordRecoveryComponent from "./containers/login/password-recovery/password-recovery.component";
import ResetPasswordComponent from "./containers/login/password-recovery/reset-password.component";

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import TagManager from 'react-gtm-module';

// Environment 
const environment = process.env.REACT_APP_ENVIRONMENT;

// Google Tag Manager ID, must be something like GTM-000000
const gtm_id = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

const tagManagerArgs = {
    gtmId: gtm_id,
}

if (environment === 'production'){
    TagManager.initialize(tagManagerArgs);
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#284358',
        },
        secondary: {
            main: '#00e2b6'
        },
        text: {
            primary: '#284358'
        }
    },
});


export default function App(props) {
    const [loginStatus, setLoginStatus] = React.useState(false);
    const [sessionId, setSessionId] = React.useState(-1);
    const [showLoginError, setShowLoginError] = React.useState(false);
    const [showRegistrationError, setShowRegistrationError] = React.useState(false);

    const loginErrorHandler = (hasErrorOccurred) => {
        setShowLoginError(hasErrorOccurred);
    }

    const registrationErrorHandler = (hasErrorOccurred) => {
        setShowRegistrationError(hasErrorOccurred);
    }

    const triggerLogIn = (props, accessToken) => {
        setLoginStatus(true);
        setSessionId(accessToken);
        props.history.push('/app/tests');
    }

    const logOutHandler = (props) => {
        sessionStorage.clear();
        setLoginStatus(false);
        props.history.push('/login');
    }

    useEffect(() => {
        let mounted = true;
        if (mounted && sessionStorage.getItem('access_token')) {
            setLoginStatus(true);
            setSessionId(sessionStorage.getItem('access_token'));
        }
        return () => mounted = false;
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={(props) => <LoginComponent {...props}
                        error={loginErrorHandler}
                        showLoginError={showLoginError}
                        login={triggerLogIn} />} />
                    <Route path="/registration"
                        component={(props) => <RegistrationComponent {...props}
                            error={registrationErrorHandler}
                            showError={showRegistrationError}
                            login={triggerLogIn} />} />
                    <Route path="/reset"
                        component={(props) => <ResetPasswordComponent {...props}
                            login={triggerLogIn} />} />
                    <Route path="/recovery"
                        component={(props) => <PasswordRecoveryComponent {...props}
                            error={registrationErrorHandler}
                            showError={showRegistrationError} />} />
                    <Route path="/app" component={(props) => <Main {...props} logOut={logOutHandler}
                        loginStatus={loginStatus}
                        sessionId={sessionId} />} />
                    <Redirect from={'/'} to={'/login'} />
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
}