import axios from 'axios';

/**
 * Axios client instance to be used by the project.
 */
export const API_CLIENT = axios.create({
	baseURL: process.env.REACT_APP_UIBACKEND_EXPOSED_BASE_URL || 'https://backend.helloplato.com/',
	withCredentials: true,
});

export default API_CLIENT;