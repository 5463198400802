import React, { useEffect, useReducer } from 'react';
import {
    Button,
    TextField
} from '@material-ui/core';
import _ from "lodash";
import API from '../../api';
import './profile.component.scss'

const initialState = {
    id: undefined,
    firstName: '',
    lastName: '',

    // General data
    isReady: false,

    // Validation information
    validation: false,
}

function reducer(state, action) {
    const currentState = _.cloneDeep(state);
    switch (action.type) {
        case 'mountUpdate':
            return {
                ...currentState,
                id: action.data.id,
                firstName: action.data.firstName,
                lastName: action.data.lastName
            }
        case 'updateFirstName':
            return {
                ...currentState,
                firstName: action.data.firstName,
            }
        case 'updateLastName':
            return {
                ...currentState,
                lastName: action.data.lastName,
            }
        case 'invalidateStatus':
            return {
                ...currentState,
                validation: true
            }
        case 'validateStatus':
            return {
                ...currentState,
                validation: false
            }
        default:
            return state;
    }
}

export default function ProfileComponent(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        firstName,
        lastName,
        validation,
    } = state;

    const cancelEditing = () => {
        props.history.push('/app/tests/');
    }

    const saveData = () => {
        if (isValidDataToSave()) {
            const { firstName, lastName } = state;
            let data = {}
            data.first_name = firstName;
            data.last_name = lastName;
            API.Users.updateUsers(data).then(({ data }) => {
              dispatch({ type: 'mountUpdate', data });
              props.history.push('/app/tests/');
            });
        }
    }

    const isValidDataToSave = () => {
        if (!state.firstName) {
            dispatch({ type: 'invalidateStatus' });
            return false;
        } else if (!state.lastName) {
            dispatch({ type: 'invalidateStatus' });
            return false;
        }
        return true;
    }

    useEffect(() => {
        let mounted = true;
        API.Users.getUsers().then(({ data }) => {
            if(mounted) {
                dispatch({ type: 'mountUpdate', data });
            }
        });
        return () => mounted = false;
    }, []);

    return (
        <div className='cast-card cast-card--init'>
            <div className='cast-form-profile'>
                <div className='cast-form-profile__title'>
                    Your Profile
                </div>
                <div className='cast-form-profile__section'>
                    <div>
                        <TextField value={firstName}
                            error={!firstName && validation}
                            helperText={!firstName && validation ? 'Invalid value' : ''}
                            onChange={(e) => dispatch({ type: 'updateFirstName', data: { firstName: e.target.value } })}
                            className='cast-form-profile__field' label="First Name" variant="outlined" />
                    </div>
                    <div>
                        <TextField value={lastName}
                            error={!lastName && validation}
                            helperText={!lastName && validation ? 'Invalid value' : ''}
                            onChange={(e) => dispatch({ type: 'updateLastName', data: { lastName: e.target.value } })}
                            className='cast-form-profile__field' label="Last Name" variant="outlined" />
                    </div>
                </div>
            </div>
            <div className='cast-card__action-group'>
                <Button onClick={() => cancelEditing()} className='cast-card__action' variant="contained">
                    Cancel
                    </Button>
                <Button onClick={() => saveData()} className='cast-card__action' variant="contained"
                    color="primary">
                    Save
                    </Button>
            </div>
        </div >
    );
}