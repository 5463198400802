import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {  Button, Paper, Table, TableBody, TableCell, TableRow, TableContainer, TableHead } from "@material-ui/core";
import { getEitherOrData, eitherOrOptions, getMultipleChoiceData, multipleChoiceOptions,
         getRatingScaleData, ratingScaleOptions, getFieldsetLegend } from './survey-activity-dashboard';
import { Doughnut, Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './survey-activity-dashboard.scss';
import API from '../../api';

const invertDirection = {
    asc: "desc",
    desc: "asc"
};

function SurveyActivityDashboardDetails(props) {
    const [reportColumnToSort, setReportColumnToSort] = React.useState('');
    const [reportSortDirection, setReportSortDirection] = React.useState('asc');
    const [reportSummary, setReportSummary] = React.useState([]);
    const [reports, setReports] = React.useState([]);
    const [test, setTest] = React.useState({});

    const handleSort = (columnName) => {
        setReportColumnToSort(columnName);
        setReportSortDirection(reportColumnToSort === columnName ? invertDirection[reportSortDirection] : "asc");
    };

    useEffect(() => {
        let mounted = true;
        async function getReports(){
            if (props.isLoggedIn) {
                try {
                    const reportSummaryResponse = await API.Tests.getTestsReportSummary(props.match.params.id);
                    const reportResponse = await API.Tests.getTestsReports(props.match.params.id);
                    const testResponse = await API.Tests.getTests(props.match.params.id);
                    if (mounted && reportSummaryResponse.status === 200 && reportResponse.status === 200 && testResponse.status === 200) {
                        setReportSummary(reportSummaryResponse.data);
                        setTest(testResponse.data);
                        // We skip reports without student answers
                        const reportsFiltered = reportResponse.data.filter(report => {
                            if (report.studentAnswers.length > 0) {
                                return report;
                            }
                            return null;
                        })
                        setReports(reportsFiltered);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
        getReports();
        return () => mounted = false;
    }, [props.isLoggedIn, props.match.params.id]);

    const renderDashboard = (reportSummary) => {
        const questionSelected = reportSummary[props.match.params.number];
        if (!questionSelected) return
        if (questionSelected.type === 'uff'){
            return (
                <fieldset value={questionSelected.prompt} disabled className="survey-activity-dashboard-fieldset">
                    <legend>{getFieldsetLegend(questionSelected, questionSelected)}</legend>
                    <h4>{questionSelected.prompt}</h4>
                    <div className='survey-activity-dashboard-total-responses'>{`Total Responses: ${questionSelected.total}`}</div>
                </fieldset>
            )
        } else if (questionSelected.type === 'ut/f'){
            return (
                <fieldset value={questionSelected.prompt} disabled className="survey-activity-dashboard-fieldset">
                    <legend>{getFieldsetLegend(questionSelected, questionSelected)}</legend>
                    <h4>{questionSelected.prompt}</h4>
                    <div className='survey-activity-dashboard-total-responses'>{`Total Responses: ${questionSelected.total}`}</div>
                    <div className='survey-activity-chart-inner-container-utf'>
                        <Doughnut options={eitherOrOptions} data = {getEitherOrData(questionSelected)}/>
                    </div>
                </fieldset>
            )
        } else if (questionSelected.type === 'umc' || questionSelected.type === 'uls'){
            return (
                <fieldset value={questionSelected.prompt} disabled className="survey-activity-dashboard-fieldset">
                    <legend>{getFieldsetLegend(questionSelected, questionSelected)}</legend>
                    <h4>{questionSelected.prompt}</h4>
                    <div className='survey-activity-dashboard-total-responses'>{`Total Responses: ${questionSelected.total}`}</div>
                    <div className='survey-activity-chart-inner-container-horizontal-bars'>
                        <Bar data={getMultipleChoiceData(questionSelected)} plugins={[ChartDataLabels]} options={multipleChoiceOptions} />
                    </div>
                </fieldset>
            )
        } else if (questionSelected.type === 'urs'){
            return (
                <fieldset value={questionSelected.prompt} disabled className="survey-activity-dashboard-fieldset">
                    <legend>{getFieldsetLegend(questionSelected, questionSelected)}</legend>
                    <h4>{questionSelected.prompt}</h4>
                    <div className='survey-activity-dashboard-total-responses'>{`Total Responses: ${questionSelected.total}`}</div>
                    <div className='survey-activity-dashboard-average-score'>{`Average Score: ${questionSelected.average}`}</div>
                    <div className='survey-activity-chart-inner-container-horizontal-bars'>
                        <Bar data={getRatingScaleData(questionSelected)} options={ratingScaleOptions} />
                    </div>
                </fieldset>
            )
        } else {
            return null
        }
    }

    const getHeadColumns = (reportSummary) => {
        const questionSelected = reportSummary[props.match.params.number];
        if (!questionSelected) return
        if (questionSelected.type === 'uff'){
            return (
                <TableCell align="center">
                    {questionSelected.prompt}
                </TableCell>
            )
        } else if (questionSelected.type === 'ut/f' || questionSelected.type === 'umc' || questionSelected.type === 'uls') {
            return (
                questionSelected.choices.map((choice, idx) => {
                    return (
                        <TableCell key={idx} align="center">
                            {choice.label}
                        </TableCell>
                    )
                })
            )
        } else if (questionSelected.type === 'urs') {
            return (
                questionSelected.choices.map((choice, idx) => {
                    return (
                        <TableCell key={idx} align="center">
                            {idx + 1}
                        </TableCell>
                    )
                })
            )
        } else {
            return null
        }
    }

    const getBodyColumns = (reportSummary, report) => {
        const answer = report.studentAnswers[props.match.params.number];
        const questionSelected = reportSummary[props.match.params.number];
        if (!questionSelected || !answer) return
        if (answer.questionType === 'uff'){
            return(
                <TableCell align="center">
                    {answer.studentResponse[0]}
                </TableCell>
            )
        } else if (answer.questionType === 'ut/f') {
            return (
                questionSelected.choices.map((choice, idx) => {
                    return (
                        <TableCell key={idx} align="center">
                            {answer.studentResponse.includes((idx + 1).toString()) ? choice.label : ''}
                        </TableCell>
                    )
                })
            )
        } else if (answer.questionType === 'umc') {
            return (
                questionSelected.choices.map((choice, idx) => {
                    return (
                        <TableCell key={idx} align="center">
                            {answer.studentResponse.includes((idx + 1).toString()) ? String.fromCharCode(65 + idx) : ''}
                        </TableCell>
                    )
                })
            )
        } else if (answer.questionType === 'uls' || answer.questionType === 'urs') {
            return (
                questionSelected.choices.map((choice, idx) => {
                    return (
                        <TableCell key={idx} align="center">
                            {answer.studentResponse.includes((idx + 1).toString()) ? idx + 1 : ''}
                        </TableCell>
                    )
                })
            )
        } else {
            return null
        }
    }

    let iconClass = 'cast-table__sort-icon ';
    if (reportSortDirection === 'desc') iconClass = iconClass + 'cast-table__sort-icon--desc ';

    const getFormattedDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        var options = {
            year: "numeric",
            month: "2-digit",
            day: "numeric"
        };
        return date.toLocaleString("en", options);
    }

    return (
        <div className='cast-card cast-card--init'>
            <div className='cast-form'>
                <div className='cast-form__section-report'>
                    <div className='cast-form__title'>
                        Learning Activity: {test.name}
                    </div>
                    <Link className='cast-toolbar__action' to={`/app/report/${props.match.params.id}`} exact="true">
                        <Button variant="contained">
                            Back
                        </Button>
                    </Link>
                </div>
                <div className='cast-card cast-card--init survey-activity-summary-container'>
                    {renderDashboard(reportSummary)}
                </div>
                <div>
                <TableContainer className={!reports.length ? 'cast-table cast-table--empty' : 'cast-table'} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow className="survey-activity-dashboard-details-header">
                                <TableCell onClick={() => handleSort('studentName')} align="left">
                                    Name
                                    <svg
                                        className={reportColumnToSort === 'studentName' ? iconClass + 'cast-table__sort-icon--visible' : iconClass}
                                        viewBox="0 0 24 24">
                                        <path d="M7.406 15.422l-1.406-1.406 6-6 6 6-1.406 1.406-4.594-4.594z"/>
                                    </svg>
                                </TableCell>
                                <TableCell align="center">
                                    Date
                                </TableCell>
                                {getHeadColumns(reportSummary)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reports.map(report => (
                                <TableRow key={report.id}>
                                    <TableCell component="th" scope="row">{report.studentName}</TableCell>
                                    <TableCell align="center">{getFormattedDate(report.finalTimestamp)}</TableCell>
                                    {getBodyColumns(reportSummary, report)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </div>
            </div>
        </div>
    );
}

export default SurveyActivityDashboardDetails;