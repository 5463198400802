import React, { useEffect } from 'react';
import { Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Menu, MenuItem, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";
import DeleteConfirmationComponent from "../delete-confirmation/delete-confirmation.component";
import DuplicateConfirmationComponent from "../duplicate-confirmation/duplicate-confirmation.component";
import UploadDataComponent from "../upload-data/upload-data.component";
import SearchComponent from "../search/search.component";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import fileDownload from 'js-file-download';
import _ from "lodash";
import API from '../../api';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function TestTableComponent(props) {
    const [exportingTests, setExportingTests] = React.useState(false);
    const [exportingArray, setExportingArray] = React.useState([]);
    const [modalResponse, setOpenModalResponse] = React.useState(false);
    const [modalResponseMessage, setModalResponseMessage] = React.useState("")
    const [modalResponseMessageStatus, setModalResponseMessageStatus] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const vertical = 'top';
    const horizontal = 'right';

    const handleSetModalInitialExport = () => {
        setModalResponseMessage('Please select on the table the activities you want to export. Once you are ready click on the "Export" button again.');
        setModalResponseMessageStatus('info');
        setOpenModalResponse(true);
    }

    const handleSetModalExportSuccess = () => {
        setModalResponseMessage('Your selected activities have been exported successfully');
        setModalResponseMessageStatus('success');
        setOpenModalResponse(true);
        setExportingArray([]);
    }

    const handleSetModalExportError = () => {
        setModalResponseMessage('Error exporting the selected activities.');
        setModalResponseMessageStatus('error');
        setOpenModalResponse(true);
        setExportingArray([]);
    }

    const handleSetModalMissingName = () => {
        setModalResponseMessage('Please click on the "Profile" button and fill out your first and last name. Afterwards click on "Save" to complete your profile.');
        setModalResponseMessageStatus('info');
        setOpenModalResponse(true);
    }

    const handleSetCloseModalResponse = () => {
        setOpenModalResponse(false);
        setModalResponseMessageStatus("");
        setModalResponseMessage("");
    }

    const handleClickExport = async () => {
        if (exportingTests && exportingArray.length > 0) {
            try {
                const response = await API.Tests.exportTests({'tests_ids': exportingArray}, {responseType: 'blob'});
                fileDownload(response.data, 'Hello PLATO - Exported Tests.xlsx');
                handleSetModalExportSuccess();
            } catch (error) {
                handleSetModalExportError();
                console.log(error);
            }
            setExportingTests(false);
        } else if (exportingTests && exportingArray.length === 0) {
            setExportingTests(false);
        } else {
            setExportingTests(true);
            handleSetModalInitialExport();
        }
    }

    const handleSelectTest = (id, checked) => {
        let exportTestArray = _.cloneDeep(exportingArray);
        const exportTestExists = exportingArray.indexOf(id) !== -1;
        if (!exportTestExists && checked) {
            exportTestArray.push(id);
        } else if (exportTestExists && !checked) {
            exportTestArray = exportTestArray.filter(item => item !== id);
        }
        setExportingArray(exportTestArray);
    }

    const formatCourseReq = (data) => {
        const courseData = {
            name: data.name,
            questions: data.course.courseData,
            order_id: data.id
        };

        if (data.status === 'published') {
            courseData.published = true;
        } else {
            courseData.published = false;
        }

        return courseData;
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = async (event, type) => {
        setAnchorEl(null);
    }
    
    useEffect(() => {
        let mounted = true;
        API.Users.getUsers().then(({ data }) => {
            if(!data.firstName || !data.lastName) {
                if (mounted) {
                    handleSetModalMissingName()
                }
            }
        });
        return () => mounted = false;
    }, []);

    let iconClass = 'cast-table__sort-icon ';
    if (props.sortDirection === 'desc') iconClass = iconClass + 'cast-table__sort-icon--desc ';

    return (
        <React.Fragment>
            <div className={props.initialized ? 'cast-card cast-card--init' : 'cast-card'}>
                <div className={props.isSearchOpen ? 'cast-toolbar cast-toolbar--search-show' : 'cast-toolbar cast-toolbar--search-hide'}>
                    <div className='cast-toolbar__section'>
                        <div className='cast-toolbar__title'>
                            Activities
                        </div>
                        <SearchComponent searchInput={props.searchInput}
                            searchToggle={props.searchToggle}
                            isOpen={props.isSearchOpen}
                            valueChange={props.valueChange} />
                    </div>
                    <div className='cast-toolbar__section'>
                        <Button className='cast-toolbar__action' variant="contained" onClick={handleClickExport}>
                            <CloudDownloadIcon className='cast-toolbar__action-icon cast-toolbar__action-icon--blue'/>
                            Export
                        </Button>
                        <UploadDataComponent updateData={props.setUpdatedTestData}
                            formatCourseReq={formatCourseReq}
                            newDataIndex={props.data ? (props.data.length ? props.data[props.data.length - 1].id : 0) : 0} />
                        <Button 
                            variant="contained" 
                            color="primary" 
                            aria-controls="create-activity-menu" 
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <svg viewBox="0 0 24 24" fill='#fff' width='24' height='24'
                                className='cast-toolbar__action-icon'>
                                <path
                                    d="M12 20.016c4.406 0 8.016-3.609 8.016-8.016s-3.609-8.016-8.016-8.016-8.016 3.609-8.016 8.016 3.609 8.016 8.016 8.016zM12 2.016c5.531 0 9.984 4.453 9.984 9.984s-4.453 9.984-9.984 9.984-9.984-4.453-9.984-9.984 4.453-9.984 9.984-9.984zM12.984 6.984v4.031h4.031v1.969h-4.031v4.031h-1.969v-4.031h-4.031v-1.969h4.031v-4.031h1.969z" />
                            </svg>
                            Create
                        </Button>
                            <Menu
                                id="create-activity-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                style={{marginTop: '25px'}}
                            >
                                <Link
                                    className='cast-toolbar__action'
                                    to={{ pathname:'/app/tests/add', state: { activitySelected: 'learning' } }}
                                    exact="true"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <MenuItem onClick={
                                        
                                        (e) => handleClose(e, 'learning')
                                    }>
                                        <ListItemText style={{color: 'rgb(40, 67, 88)'}} primary="Learning Activity" />
                                    </MenuItem>
                                </Link>
                                <Link
                                    className='cast-toolbar__action'
                                    to={{ pathname:'/app/tests/add', state: { activitySelected: 'survey' } }}
                                    exact="true"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <MenuItem onClick={
                                        (e) => handleClose(e, 'survey')
                                    }>
                                        <ListItemText style={{color: 'rgb(40, 67, 88)'}} primary="Survey Activity" />
                                    </MenuItem>
                                </Link>
                            </Menu>
                    </div>
                </div>
                <TableContainer className={!props.data.length ? 'cast-table cast-table--empty' : 'cast-table'} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    exportingTests ?
                                    <TableCell align='left'>
                                        Select
                                    </TableCell>
                                    :
                                    null
                                }
                                <TableCell onClick={() => props.handleSort('name')} align={exportingTests ? 'center' : 'left'}>
                                    Name
                                    <svg
                                        className={props.columnToSort === 'name' ? iconClass + 'cast-table__sort-icon--visible' : iconClass}
                                        viewBox="0 0 24 24">
                                        <path d="M7.406 15.422l-1.406-1.406 6-6 6 6-1.406 1.406-4.594-4.594z" />
                                    </svg>
                                </TableCell>
                                <TableCell align="center">Activity Code</TableCell>
                                <TableCell align="center" onClick={() => props.handleSort('type')}>
                                    Activity Type
                                    <svg
                                        className={props.columnToSort === 'date' ? iconClass + 'cast-table__sort-icon--visible' : iconClass}
                                        viewBox="0 0 24 24">
                                        <path d="M7.406 15.422l-1.406-1.406 6-6 6 6-1.406 1.406-4.594-4.594z" />
                                    </svg>
                                </TableCell>
                                <TableCell onClick={() => props.handleSort('status')} align="center">
                                    Status
                                    <svg
                                        className={props.columnToSort === 'status' ? iconClass + 'cast-table__sort-icon--visible' : iconClass}
                                        viewBox="0 0 24 24">
                                        <path d="M7.406 15.422l-1.406-1.406 6-6 6 6-1.406 1.406-4.594-4.594z" />
                                    </svg>
                                </TableCell>
                                <TableCell onClick={() => props.handleSort('date')} align="center">
                                    Creation Date
                                    <svg
                                        className={props.columnToSort === 'date' ? iconClass + 'cast-table__sort-icon--visible' : iconClass}
                                        viewBox="0 0 24 24">
                                        <path d="M7.406 15.422l-1.406-1.406 6-6 6 6-1.406 1.406-4.594-4.594z" />
                                    </svg>
                                </TableCell>
                                <TableCell align="center">Exported to Classroom</TableCell>
                                <TableCell align="center">Review</TableCell>
                                <TableCell align="right"> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.data.map((row) => {
                                if (Object.keys(row).length === 0){ 
                                    return null
                                } else {
                                    return <TableRow key={row.id}>
                                    {
                                        exportingTests ?
                                        <TableCell>
                                            <Checkbox
                                                color="primary"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                onChange={e => {handleSelectTest(row.id, e.target.checked)}}
                                            />
                                        </TableCell>
                                        :
                                        null
                                    }
                                    <TableCell component="th" scope="row" align={exportingTests ? "center" : "left"}>
                                        <Link className='cast-table__link' to={row.status.toLowerCase() === 'published' ? `/app/tests/${row.id}` : `/app/tests/${row.id}/edit` }
                                            exact="true">
                                            {row.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="center">{row.status.toLowerCase() === 'published' ? row.testCode : null}</TableCell>
                                    <TableCell align="center">{row.type.charAt(0).toUpperCase() + row.type.slice(1)}</TableCell>
                                    <TableCell align="center">{row.status.charAt(0).toUpperCase() + row.status.slice(1)}</TableCell>
                                    <TableCell align="center">{row.date}</TableCell>
                                    <TableCell align="center">
                                        {
                                            row.exportedToGoogle && (
                                                <CloudDoneIcon style={{ marginTop: "1rem" }} />
                                            )
                                        }
                                    </TableCell>
                                    <TableCell align="center" className='cast-table__link'>
                                        <Link to={'/app/report/' + row.id}
                                            exact="true">
                                            {row.reports ? row.reports.length : 'Review'}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="right">
                                        <div className='cast-table__action-group'>
                                            {
                                                row.status.toLowerCase() === 'published' ?
                                                <DuplicateConfirmationComponent duplicate={() => props.duplicateTest(row.id)} />
                                                :
                                                null
                                            }
                                            <DeleteConfirmationComponent deleteTest={() => props.deleteTest(row.id, row.exportedToGoogle)} setUpdatedTestData={props.setUpdatedTestData} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                                }
                            }
                        )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Snackbar open={modalResponse}
                autoHideDuration={10000}
                onClose={handleSetCloseModalResponse}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert severity={modalResponseMessageStatus}>
                    {modalResponseMessage}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}