import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import SearchComponent from "../search/search.component";
import API from '../../api';

const invertDirection = {
    asc: "desc",
    desc: "asc"
};

const useStyles = makeStyles({
    correct: {
        backgroundColor: 'lightgreen'
    },
    incorrect: {
        backgroundColor: '#ff6b6b'
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        fontSize: '18px'
    }
});

export default function StudentReportComponent(props) {
    const [reportColumnToSort, setReportColumnToSort] = React.useState('');
    const [reportSortDirection, setReportSortDirection] = React.useState('asc');
    const [searchInput, setSearchInput] = React.useState('');
    const [isSearchOpen, setIsSearchOpen] = React.useState(false);
    const [initialized, setInitialized] = React.useState(false);
    const [filteredReport, setFilteredReport] = React.useState([]);
    const [reports, setReports] = React.useState([]);
    const [test, setTest] = React.useState({});
    const [questionData, setQuestionData] = React.useState([]);

    const searchToggle = (isOpen) => {
        setIsSearchOpen(isOpen);
        if (!isOpen) {
            setSearchInput('');
            setFilteredReport(reports);
        }
    };

    const valueChange = (value) => {
        let searchValue = value;
        setFilteredReport(reports.filter(option => option.studentName.trim().toLowerCase().includes(searchValue.toLowerCase())));
        setSearchInput(searchValue);
    };

    const handleSort = (columnName) => {
        setReportColumnToSort(columnName);
        setReportSortDirection(reportColumnToSort === columnName ? invertDirection[reportSortDirection] : "asc");
    };

    const formatAnswers = (test, reports) => {
        const questionData = test.questions.filter(
            // Content items are not displayed
            body => !['image', 'video', 'text'].includes(body.type.toLowerCase()));
        reports.forEach(report => {
            report.studentAnswers.forEach((answer, idx) => {
                answer.studentResponsesLetters = [];
                answer.studentResponse.forEach(choice => {
                    if (questionData[idx].type === 'mc' || questionData[idx].type === 'umc') {
                        answer.studentResponsesLetters.push(String.fromCharCode(64 + parseInt(choice)));
                    } else if (questionData[idx].type === 't/f' || questionData[idx].type === 'ut/f') {
                        if (choice === '1') {
                            answer.studentResponsesLetters.push('T');
                        } else {
                            answer.studentResponsesLetters.push('F');
                        }
                    }
                });
                answer.type = questionData[idx].type;
                answer.studentResponsesLetters = answer.studentResponsesLetters.join();
            });
        });
        setQuestionData(questionData);
        setReports(reports);
    };

    useEffect(() => {
        let mounted = true;
        if (props.isLoggedIn) {
            API.Tests.getTestsReports(props.match.params.id)
                .then(response => {
                    if (mounted && response.status === 200) {
                        setFilteredReport(response.data);
                        setInitialized(true);
                        API.Tests.getTests(props.match.params.id).then((data) => {
                            if (mounted) {
                                setTest(data.data);
                                formatAnswers(data.data, response.data);
                            }
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
        return () => mounted = false;
    }, [props.isLoggedIn, props.match.params.id]);

    let iconClass = 'cast-table__sort-icon ';
    if (reportSortDirection === 'desc') iconClass = iconClass + 'cast-table__sort-icon--desc ';

    const classes = useStyles();

    const renderIcon = (isCorrect) => {
        if (typeof isCorrect === 'boolean') {
            if(isCorrect) {
                return (
                    <CheckIcon className={classes.icon} />
                )
            } else {
                return (
                    <CloseIcon className={classes.icon} />
                )
            }
        } else {
            return null;
        }
    }

    return (
        <div className={initialized ? 'cast-card cast-card--init': 'cast-card'}>
            <div className={isSearchOpen ? 'cast-toolbar cast-toolbar--search-show' : 'cast-toolbar cast-toolbar--search-hide'}>
                <div className='cast-toolbar__section'>
                    <div className='cast-toolbar__title'>
                        {test.name}
                    </div>
                    <SearchComponent searchInput={searchInput}
                                     searchToggle={searchToggle}
                                     isOpen={isSearchOpen}
                                     valueChange={valueChange}/>
                </div>
                <div className='cast-toolbar__section'>
                    <Link className='cast-toolbar__action' to={`/app/report/${props.match.params.id}`} exact="true">
                        <Button variant="contained">
                            Back
                        </Button>
                    </Link>
                </div>
            </div>
            <TableContainer className={!filteredReport.length ? 'cast-table cast-table--empty' : 'cast-table'}
                            component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell onClick={() => handleSort('studentName')} align="left">
                                Name
                                <svg
                                    className={reportColumnToSort === 'studentName' ? iconClass + 'cast-table__sort-icon--visible' : iconClass}
                                    viewBox="0 0 24 24">
                                    <path d="M7.406 15.422l-1.406-1.406 6-6 6 6-1.406 1.406-4.594-4.594z"/>
                                </svg>
                            </TableCell>
                            {
                                questionData[0] ? questionData.map((question, idx) => (
                                    <TableCell align="center" key={idx}>
                                        {`Q${idx+1}`}
                                    </TableCell>
                                )) : null
                            }
                            <TableCell onClick={() => handleSort('score')} align="center">
                                Score
                                <svg
                                    className={reportColumnToSort === 'score' ? iconClass + 'cast-table__sort-icon--visible' : iconClass}
                                    viewBox="0 0 24 24">
                                    <path d="M7.406 15.422l-1.406-1.406 6-6 6 6-1.406 1.406-4.594-4.594z"/>
                                </svg>
                            </TableCell>
                            <TableCell onClick={() => handleSort('duration')} align="center">
                                Duration
                                <svg
                                    className={reportColumnToSort === 'duration' ? iconClass + 'cast-table__sort-icon--visible' : iconClass}
                                    viewBox="0 0 24 24">
                                    <path d="M7.406 15.422l-1.406-1.406 6-6 6 6-1.406 1.406-4.594-4.594z"/>
                                </svg>
                            </TableCell>
                            <TableCell align="center">
                                Date
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredReport.map((report, idx) => (
                            <TableRow key={report.id}>
                                <TableCell component="th" scope="row">
                                    {
                                        report.studentAnswers.length > 0 ?
                                            <Link to={`/app/report/${props.match.params.id}/student/${idx}`}>
                                                {report.studentName}
                                            </Link>
                                        :
                                            report.studentName
                                    }
                                </TableCell>
                                {
                                    questionData[0] ? questionData.map((question, idx) => (
                                        report.studentAnswers.length > 0 ?
                                            <TableCell align="center" key={idx} className={report.studentAnswers[idx] && typeof report.studentAnswers[idx].responseWasCorrect === 'boolean' ? report.studentAnswers[idx].responseWasCorrect ? classes.correct : classes.incorrect : ''}>
                                                {
                                                    report.studentAnswers[idx].type === 'ff' ?
                                                        renderIcon(report.studentAnswers[idx].responseWasCorrect)
                                                    :
                                                    report.studentAnswers[idx] && report.studentAnswers[idx].studentResponsesLetters
                                                }
                                            </TableCell>
                                        :
                                            <TableCell align="center" key={idx}>
                                            </TableCell>
                                    )) : null
                                }
                                <TableCell align="center">{report.score === -1 ? 'Not yet graded' : report.score}</TableCell>
                                <TableCell align="center">{report.duration}</TableCell>
                                <TableCell align="center">{report.finalDate}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}