import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { Link } from 'react-router-dom';

export default function LearningActivityDashboardComponent({ reportSummary, PercentBar, ReportDetailComponent, id }) {
    return(
        <>
            <Grid className='cast-card__grid-container' container spacing={3}>
                    <Grid className='cast-card__bar' item xs={12}>
                        <PercentBar low={reportSummary.minScore} average={reportSummary.averageScore} high={reportSummary.maxScore} />
                    </Grid>
                    <Grid className='cast-card__labels-left' item xs={12} sm={6}>
                        <ReportDetailComponent label="Number of Questions" value={reportSummary.questionsAmount} />
                        <ReportDetailComponent label="Attempts/Completed" value={reportSummary.testAttempts && reportSummary.testsCompleted ? `${reportSummary.testAttempts}/${reportSummary.testsCompleted}` : null} />
                    </Grid>
                    <Grid className='cast-card__labels-right' item xs={12} sm={6}>
                        <ReportDetailComponent label="Average Time to Complete" value={reportSummary.averageDuration} />
                        <ReportDetailComponent label="Shortest Time" value={reportSummary.minDuration} />
                        <ReportDetailComponent label="Longest Time" value={reportSummary.maxDuration} />
                    </Grid>
                </Grid>
                <div className='cast-card__buttons'>
                    <Link
                        to={`/app/report/${id}/students`}
                        className='cast-card__button'
                    >
                        <Button variant="contained">
                            Student Performance
                        </Button>
                    </Link>
                    <Link
                        to={`/app/report/${id}/performance`}
                        className='cast-card__button'
                    >
                        <Button variant="contained">
                            Activity Performance
                        </Button>
                    </Link>
                </div>
        </>
    )
}