import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import readXlsxFile from 'read-excel-file'
import './upload-data.component.scss';
import isEmpty from 'lodash/isEmpty';
import API from '../../api';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    uploadButtonOverride: {
        color: 'white !important'
    }
}));


export default function UploadDataComponent(props) {
    const [open, setOpen] = React.useState(false);
    const [fileIsUploaded, uploadFile] = React.useState(false);
    const [tests, testsChange] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [modalResponse, setOpenModalResponse] = React.useState(false);
    const [modalResponseMessage, setModalResponseMessage] = React.useState("")
    const [modalResponseMessageStatus, setModalResponseMessageStatus] = React.useState("");
    const vertical = 'top';
    const horizontal = 'right';

    const handleIsLoading = () => {
        setIsLoading(true);
    }

    const handleIsNotLoading = () => {
        setIsLoading(false);
    }

    const handleSetOpenModalResponse = () => {
        setOpenModalResponse(true);
    }

    const handleSetCloseModalResponse = () => {
        setOpenModalResponse(false);
        setModalResponseMessageStatus("");
        setModalResponseMessage("");
    }

    const handleSetModalResponse = (imported, created) => {
        if (imported === created) {
            setModalResponseMessage(`We have successfully imported ${created} out of ${imported} activities from your file!`);
            setModalResponseMessageStatus("success");
        } else if (created === 0) {
            setModalResponseMessage(`We were not able to import any of the ${imported} activities in your file.`);
            setModalResponseMessageStatus("error");
        } else {
            setModalResponseMessage(`We were only able to import ${created} out of ${imported} activities in your file.`);
            setModalResponseMessageStatus("warning");
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        uploadFile(false);
    };

    const checkFilePresent = (e) => {
        const file = e.target.files;
        const isFileValid = e.target.files.length;

        if (isFileValid) {
            processUploadedData(file[0]);
        }

        isFileValid ? uploadFile(true) : uploadFile(false);
    };

    const cleanCorrectAnswer = (correctAnswers) => {
        const correctAnswersArray = correctAnswers.split(',');
        let cleanArray = [];
        for (const answer of correctAnswersArray) {
            cleanArray.push(answer.trim().toLowerCase());
        }
        return cleanArray
    }

    const fillQuestionByType = (question, index) => {
        const currentQuestion = {};
        currentQuestion.id = index;
        currentQuestion.type = question[1].toLowerCase();
        if (question[1].toLowerCase() === 't/f' || question[1].toLowerCase() === 'tf' || question[1].toLowerCase() === 'ut/f') {
            currentQuestion.question = question[5].replace(/(\r\n|\n|\r)/gm, "");
            currentQuestion.booleanAnswer = question[6] ? Boolean(question[6].toString().toLowerCase()) : '';
            currentQuestion.hasFeedback = Boolean(question[17]) || Boolean(question[18]) || Boolean(question[12]);
            currentQuestion.feedbackMessageForCorrectBooleanAnswer = question[17] ? question[17] : '';
            currentQuestion.feedbackMessageForWrongBooleanAnswer = question[18] ? question[18] : '';
            if (question[1].toLowerCase() === 'ut/f') {
                currentQuestion.feedbackMessage = question[12] ? question[12] : '';
            }
        } else if (question[1].toLowerCase() === 'mc' || question[1].toLowerCase() === 'umc' || question[1].toLowerCase() === 'uls' || question[1].toLowerCase() === 'urs') {
            currentQuestion.question = question[5].replace(/(\r\n|\n|\r)/gm, "");
            currentQuestion.multipleAnswerList = [];
            currentQuestion.multipleAnswerList.push({key: 0, active: question[7] ? true : false, answer: question[6] ? cleanCorrectAnswer(question[6].toString()).indexOf('a') !== -1 ? true : false : '', answerText: question[7] ? question[7] : '', hasFeedback: Boolean(question[12]), feedbackMessage: question[12] ? question[12] : ''});
            currentQuestion.multipleAnswerList.push({key: 1, active: question[8] ? true : false, answer: question[6] ? cleanCorrectAnswer(question[6].toString()).indexOf('b') !== -1 ? true : false : '', answerText: question[8] ? question[8] : '', hasFeedback: Boolean(question[13]), feedbackMessage: question[13] ? question[13] : ''});
            currentQuestion.multipleAnswerList.push({key: 2, active: question[9] ? true : false, answer: question[6] ? cleanCorrectAnswer(question[6].toString()).indexOf('c') !== -1 ? true : false : '', answerText: question[9] ? question[9] : '', hasFeedback: Boolean(question[14]), feedbackMessage: question[14] ? question[14] : ''});
            currentQuestion.multipleAnswerList.push({key: 3, active: question[10] ? true : false, answer: question[6] ? cleanCorrectAnswer(question[6].toString()).indexOf('d') !== -1 ? true : false : '', answerText: question[10] ? question[10] : '', hasFeedback: Boolean(question[15]), feedbackMessage: question[15] ? question[15] : ''});
            currentQuestion.multipleAnswerList.push({key: 4, active: question[11] ? true : false, answer: question[6] ? cleanCorrectAnswer(question[6].toString()).indexOf('e') !== -1 ? true : false : '', answerText: question[11] ? question[11] : '', hasFeedback: Boolean(question[16]), feedbackMessage: question[16] ? question[16] : ''});
            currentQuestion.multipleAnswerList.push({key: 5, active: question[20] ? true : false, answer: question[6] ? cleanCorrectAnswer(question[6].toString()).indexOf('f') !== -1 ? true : false : '', answerText: question[20] ? question[20] : '', hasFeedback: Boolean(question[25]), feedbackMessage: question[25] ? question[25] : ''});
            currentQuestion.multipleAnswerList.push({key: 6, active: question[21] ? true : false, answer: question[6] ? cleanCorrectAnswer(question[6].toString()).indexOf('g') !== -1 ? true : false : '', answerText: question[21] ? question[21] : '', hasFeedback: Boolean(question[26]), feedbackMessage: question[26] ? question[26] : ''});
            currentQuestion.multipleAnswerList.push({key: 7, active: question[22] ? true : false, answer: question[6] ? cleanCorrectAnswer(question[6].toString()).indexOf('h') !== -1 ? true : false : '', answerText: question[22] ? question[22] : '', hasFeedback: Boolean(question[27]), feedbackMessage: question[27] ? question[27] : ''});
            currentQuestion.multipleAnswerList.push({key: 8, active: question[23] ? true : false, answer: question[6] ? cleanCorrectAnswer(question[6].toString()).indexOf('i') !== -1 ? true : false : '', answerText: question[23] ? question[23] : '', hasFeedback: Boolean(question[28]), feedbackMessage: question[28] ? question[28] : ''});
            currentQuestion.multipleAnswerList.push({key: 9, active: question[24] ? true : false, answer: question[6] ? cleanCorrectAnswer(question[6].toString()).indexOf('j') !== -1 ? true : false : '', answerText: question[24] ? question[24] : '', hasFeedback: Boolean(question[29]), feedbackMessage: question[29] ? question[29] : ''});
        } else if (question[1].toLowerCase() === 'text') {
            currentQuestion.link = question[2];
        } else if (question[1].toLowerCase() === 'image' || question[1].toLowerCase() === 'video') {
            currentQuestion.link = question[3];
        } else if (question[1].toLowerCase() === 'ff' || question[1].toLowerCase() === 'uff') {
            currentQuestion.question = question[5].replace(/(\r\n|\n|\r)/gm, "");
            currentQuestion.feedbackMessage = question[12] ? question[12] : '';
            currentQuestion.hasFeedback = Boolean(question[12]);
        }
        if (currentQuestion.type === 'urs') {
            for (let i = 1; i < currentQuestion.multipleAnswerList.length; i++) {
                currentQuestion.multipleAnswerList[i].active = true;
                if(currentQuestion.multipleAnswerList[i].answerText !== '') {
                    currentQuestion.answersAmount = i + 1;
                    break;
                }
            }
        } else if (currentQuestion.type === 'uls') {
            for (let i = 0; i < currentQuestion.multipleAnswerList.length; i++) {
                currentQuestion.multipleAnswerList[i].active = currentQuestion.multipleAnswerList[i].answerText !== '' ? true : false;
            }
        }
        return currentQuestion;
    }

    const processUploadedData = (file) => {
        const tests = [];
        const unprocessedData = [];
        readXlsxFile(file).then((rows) => {
            unprocessedData.push(rows);

            let currentTest = {};

            unprocessedData[0].forEach((row, index) => {
                if (index) {
                    if (row[0]) {
                        if (Object.keys(currentTest).length) {
                            tests.push(currentTest);
                            testsChange(tests);
                        }
                        currentTest = {};
                        currentTest.name = row[0];
                        currentTest.status = row[19];
                        currentTest.questions = [];
                        if (row[1]) {
                            currentTest.questions.push(fillQuestionByType(row, 0));
                        }
                    } else {
                        if (row[1]) {
                            currentTest.questions.push(fillQuestionByType(row, index - 1));
                        }
                    }
                }
                if (unprocessedData[0].length - 1 === index) {
                    tests.push(currentTest);
                    testsChange(tests)
                }
            })
        })
    }

    const tryUploadFile = async () => {
        handleIsLoading();
        let response;
        if (tests.length === 1 && isEmpty(tests[0])) {
            setModalResponseMessage("The file you imported has no activities in it.");
            setModalResponseMessageStatus("error");
        } else {
            try {
                response = await API.Tests.importTests({'tests': tests});
            } catch (error) {
                setModalResponseMessage("Error importing activities from your file.");
                setModalResponseMessageStatus("error");
                console.log(error);
            }
            if (response && response.status === 200) {
                props.updateData();
                handleSetModalResponse(response.data.imported, response.data.created);
            } else {
                setModalResponseMessage("Error importing activities from your file.");
                setModalResponseMessageStatus("error");
            }
        }
        testsChange([]);
        handleIsNotLoading();
        handleClose();
        handleSetOpenModalResponse();
    };

    const classes = useStyles();

    return (
        <React.Fragment>
            <Button className='cast-toolbar__action' variant="contained" onClick={handleClickOpen}>
                <CloudUploadIcon className='cast-toolbar__action-icon cast-toolbar__action-icon--blue'/>
                Import
            </Button>
            <Dialog className='cast-popup'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Upload Data</DialogTitle>
                <DialogContent className='cast-popup__content'>
                    <DialogContentText id="alert-dialog-description">
                        You may upload only one Excel .xlsx file at a time to import your activities into Hello PLATO.
                        You can download a template by clicking <Link href={`${process.env.REACT_APP_UIBACKEND_EXPOSED_BASE_URL}downloads/templates/import`}>here</Link>.
                    </DialogContentText>
                    <input type="file" id="selectData" accept=".xlsx,.xlsm" name="importData"
                           onChange={(e) => checkFilePresent(e)}/>
                </DialogContent>
                {
                    !isLoading ?
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained">
                            Cancel
                        </Button>
                        <Button onClick={tryUploadFile} variant="contained" color="primary" className={fileIsUploaded ? classes.uploadButtonOverride : ''} disabled={!fileIsUploaded}
                                autoFocus>
                            Upload
                        </Button>
                    </DialogActions>
                    :
                    <div className={classes.root}>
                        <LinearProgress />
                    </div>
                }
            </Dialog>
            <Snackbar open={modalResponse}
                autoHideDuration={6000}
                onClose={handleSetCloseModalResponse}
                anchorOrigin={{ vertical, horizontal }}
            >
                <Alert severity={modalResponseMessageStatus}>
                    {modalResponseMessage}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}