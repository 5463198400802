import React, { useEffect } from 'react';
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import API from '../../api';
import SurveyActivityDashboardComponent from './survey-activity-dashboard';
import LearningActivityDashboardComponent from './learning-activity-dashboard';

export function ReportDetailComponent(props) {
    const { label, value } = props;

    const scoreValueStyle = {
        fontWeight: 'bold',
        fontSize: '1.25rem',
    };

    return (
        <div className='cast-card__labels'>
            <Typography component="span">
                {`${label} : `}
            </Typography>
            <Typography component="span" style={scoreValueStyle}>
                {value}
            </Typography>
        </div>
    )
}

export function PercentBar(props) {
    // All are numbers from 0 to 100
    const { low, average, high } = props;
    const style = {
        background: 'linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(232,255,0,1) 50%, rgba(0,255,16,1) 100%)',
        width: '100%',
        height: '3em',
        border: '0.15em solid #cbcbcb',
        borderRadius: '0.2em',
        position: 'relative',
        marginBottom: '2em',
    };

    return (
        <div style={style} className="cast-score-bar">
            {
                low === high ?
                <PercentBarScore score={average} label='Average Score' />
                :
                <div>
                    <PercentBarScore score={low} label='Low Score' />
                    <PercentBarScore score={average} label='Average Score' />
                    <PercentBarScore score={high} label='High Score' />
                </div>
            }
        </div>
    )
}

export function PercentBarScore(props) {
    const { score, label } = props;

    // TODO: Move to actual CSS once this component is finished.
    const barStyle = {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: `${score}%`,
        transform: 'translateX(-50%)',
        width: '0.2em',
        backgroundColor: 'black',
    };

    const textStyle = {
        width:'120px',
        position: 'absolute',
        top: label === 'Average Score' ? '-150%' : '100%',
        left: `${score}%`,
        transform: 'translateX(-50%)',
    };

    const scoreScoreStyle = {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        marginTop: '-5px',
        textShadow: 'black 0px 0px 2px',
        color: score < 50 ? `rgb(255, ${Math.round(5.1 * score)},16)` : `rgb(${Math.round(510 - 5.10 * score)}, 255,16)`
    };

    return (
        <div>
            <div style={barStyle} />
            <div style={textStyle}>
                <Typography component='p' align='center'>
                    {label}
                </Typography>
                <Typography style={scoreScoreStyle} component='p' align='center'>
                    {score}
                </Typography>
            </div>
        </div>
    )
}

export default function ReportDashboardComponent(props) {
    const [reportSummary, setReportSummary] = React.useState({});
    const [test, setTest] = React.useState({});

    useEffect(() => {
        let mounted = true;
        async function getReports(){
            if (props.isLoggedIn) {
                try {
                    const reportResponse = await API.Tests.getTestsReportSummary(props.match.params.id);
                    const testResponse = await API.Tests.getTests(props.match.params.id);
                    if (mounted &&  reportResponse.status === 200 && testResponse.status === 200) {
                        setReportSummary(reportResponse.data);
                        setTest(testResponse.data);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
        getReports();
        return () => mounted = false;
    }, [props.isLoggedIn, props.match.params.id]);
    return (
        <div className='cast-card cast-card--init'>
            <div className='cast-form'>
                <div className='cast-form__section-report'>
                    <div className='cast-form__title'>
                        Learning Activity: {test.name}
                    </div>
                    <Link className='cast-toolbar__action' to='/app/tests/' exact="true">
                        <Button variant="contained">
                            Back
                        </Button>
                    </Link>
                </div>
                {
                    test.type === 'survey' ? 
                    <SurveyActivityDashboardComponent 
                        reportSummary={reportSummary}
                        id={props.match.params.id}
                    />
                    : test.type === 'learning' || test.type === 'mixed' ?
                    <LearningActivityDashboardComponent
                        reportSummary={reportSummary}
                        PercentBar={PercentBar}
                        ReportDetailComponent={ReportDetailComponent}
                        id={props.match.params.id}
                    />
                    : null
                }
            </div>
        </div>
    )
}