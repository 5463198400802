import React from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import './survey-activity-dashboard.scss';
import ChartDataLabels from 'chartjs-plugin-datalabels';


export const colorsForGraphs = {
    least: '#aaeaf2', // below 20%
    mediumLeast: '#80dfeb', // 20% - 29%
    medium: '#00bfd8', // 30% - 39%
    mediumGreatest: '#1b6c83', // 40% - 49%
    greatest: '#284358' // 50% and above
}

export const getColorsForGraphs = (data) => {
    let colors = [];
    if (data.length === 2) {
        if (data[1] > data[0]) {
            colors = [colorsForGraphs.medium, colorsForGraphs.greatest]
        } else {
            colors = [colorsForGraphs.greatest, colorsForGraphs.medium]
        }
    } else {
        colors = data.map(item => 
                item >= 50 ? colorsForGraphs.greatest
                : item >= 40 && item < 50 ? colorsForGraphs.mediumGreatest
                : item >= 30 && item < 40 ? colorsForGraphs.medium
                : item >= 20 && item < 30 ? colorsForGraphs.mediumLeast
                :  colorsForGraphs.least
        )
    }
    return colors;
}

export const getEitherOrData = (question) => {
    const data = question.choices.map(item => item.amount);
    const dataPercentages = question.choices.map(item => item.percentage);
    const labels = question.choices.map(item => `${item.label} (${Math.round(item.percentage * 100)}%)`);
    return({
        datasets: [{
            data,
            backgroundColor: getColorsForGraphs(dataPercentages)
        }],
        labels,
        responsive: true,
    })
};

export const eitherOrOptions = {
    reponsive: true,
    cutout: '75%',
    maintainAspectRatio: true,
    plugins: {
        legend: {
            position: 'left',
        },
    }
};

export const getMultipleChoiceData = (question) => { // Used for Multiple Choice and Likert Scale
    const labels = question.choices.map(item => item.label);
    const dataPercentages = question.choices.map(item => item.percentage * 100);
    const data = {
        labels,
        datasets: [
            {
                label: '% of total answers',
                data: dataPercentages,
                backgroundColor: getColorsForGraphs(dataPercentages),
            },
        ],
    }
    return(data)
};

export const multipleChoiceOptions = { // Used for Multiple Choice and Likert Scale
    indexAxis: 'y',
    scales: {
        x: {
            min: 0,
            max: 100
        }
    },
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
        legend: {
            display: false,
        },
        datalabels: {
            anchor: 'end',
            align: 'right',
            offset: 10,
            formatter: function(value) {
                return Math.round(value) + '%';
            },
            font: {
                weight: 'bold'
            }
        }
    }
};

export const getRatingScaleData = (question) => {
    const dataPercentages = question.choices.map(item => item.percentage * 100);
    const dataAmounts = question.choices.map(item => item.amount);
    const labels = []
    for (let i = 0; i < question.choices.length; i++) {
        labels.push(i+1);
    }
    const data = {
        labels,
        datasets: [
            {
                label: 'total answers',
                data: dataAmounts,
                backgroundColor: getColorsForGraphs(dataPercentages),
            },
        ],
    }
    return(data)
};

export const ratingScaleOptions = {
    scales: {
        y: {
            min: 0,
            suggestedMax: 10,
            ticks: {
                stepSize: 5
            }
        }
    },
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
        legend: {
            display: false,
        }
    }
};

export const getFieldsetLegend = (question) => {
    if (question.type === 'ut/f') return `Q${question.number} - Either/Or`
    if (question.type === 'umc') return `Q${question.number} - Multiple Choice`
    if (question.type === 'uls') return `Q${question.number} - Opinion - Feedback`
    if (question.type === 'uff') return `Q${question.number} - Open Ended`
    if (question.type === 'urs') return `Q${question.number} - Rating Scale`
}

export default function SurveyActivityDashboardComponent({ reportSummary, id}) {
    return (
        <div className='cast-form'>
                {
                    reportSummary.map((item, idx) => {
                        return (
                            <div key={idx} className='cast-card cast-card--init'>
                                <div className='survey-activity-summary-container'>
                                    <div className='survey-activity-summary-inner-container'>
                                        <fieldset value={item.prompt} disabled className="survey-activity-dashboard-fieldset">
                                            <legend>{getFieldsetLegend(item)}</legend>
                                            <h4>{item.prompt}</h4>
                                            <div className='survey-activity-dashboard-total-responses'>{`Total Responses: ${item.total}`}</div>
                                            {item.type === 'urs' ?
                                            <div className='survey-activity-dashboard-average-score'>{`Average Score: ${item.average}`}</div>
                                            : null }
                                            {
                                                item.type === 'ut/f' ?
                                                    <div className='survey-activity-chart-inner-container-utf'>
                                                        <Doughnut data = {getEitherOrData(reportSummary[idx])} options={eitherOrOptions} />
                                                    </div>
                                                : item.type === 'urs' ?
                                                <div className='survey-activity-chart-inner-container-horizontal-bars'>
                                                    <Bar data={getRatingScaleData(item)} options={ratingScaleOptions} />
                                                </div>
                                                : item.type === 'umc' || item.type === 'uls' ? // UMC and ULS uses same logic
                                                <div className='survey-activity-chart-inner-container-horizontal-bars'>
                                                    <Bar data={getMultipleChoiceData(item)} plugins={[ChartDataLabels]} options={multipleChoiceOptions} />
                                                </div>
                                                : item.type === 'uff' ?
                                                null
                                                : null
                                            }
                                        </fieldset>
                                        <div className={`survey-activity-chart-detail-button ${item.type !== 'uff' ? 'survey-activity-chart-detail-button-extra-margin' : ''}`}>
                                            <Link className='cast-toolbar__action' to={`/app/report/${id}/${idx}/details`} exact="true">
                                                <Button variant="contained">
                                                    View responses
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
        </div>
    )
}