import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../../login-logo.jpg'
import API from '../../../api';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        width: '200px',
        marginBottom: '20px'
    }
}));


export default function PasswordRecoveryComponent(props) {
    const recaptchaRef = React.createRef();

    let [person, setPerson] = useState({
        email: '',
        message: '',
        messageType: ''
    });

    let [invalidEmail, setValidation] = useState(false);
    let invalid = false;

    const classes = useStyles();

    const updatePersonEmail = (e) => {
        person.email = e.target.value
        if (invalidEmail) setValidation(false);

        setPerson({ ...person });
    }

    const sendEmailForRecovery = (e) => {
        e.preventDefault();
        invalid = validateEmail(person.email);
        setValidation(invalid);
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            person.message = 'Please verify you are a human.';
            person.messageType = 'bad';
            setPerson({ ...person });
        }
        if (!invalid && recaptchaValue) {
            API.Auth.requestPasswordReset({ email: person.email, recaptcha: recaptchaValue })
                .then(response => {
                    if (response.status === 200) {
                        person.messageType = 'good';
                        person.message = 'Email with reset link was sent. Check your inbox.';
                        setPerson({ ...person });
                    }
                })
                .catch(error => {
                    if (error.response.data.message === 'Invalid reCAPTCHA') {
                        person.message = error.response.data.message;
                    } else {
                        person.message = 'Something went wrong, try again.'
                    }
                    person.messageType = 'bad';
                    setPerson({ ...person });
                });
        }
    }

    const validateEmail = (email) => {
        //eslint-disable-next-line
        const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !regExp.test(String(email).toLowerCase());
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img src={logo} className={classes.logo} alt="Logo" />
                <Typography component="h1" variant="h5">
                    Password Recovery
                </Typography>
                <form className={classes.form} noValidate>
                    <Typography component="p" align='center' variant="body1"
                        style={{ color: (person.messageType === 'good' ? 'green' : 'red') }}>
                        {person.message}
                    </Typography>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Email Address"
                        name="email"
                        value={person.email}
                        error={invalidEmail}
                        helperText={invalidEmail ? 'Invalid email' : ''}
                        onChange={(e) => updatePersonEmail(e)}
                        autoComplete="off"
                        autoFocus
                    />
                    <Grid item xs={12} align="center">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LcRnr8aAAAAAEkjTmYAz-yrJf83G9vEtvtv-djI'}
                        />
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={(e) => sendEmailForRecovery(e)}
                        className={classes.submit}>
                        Send
                    </Button>
                    <Grid container>
                        <Grid item xs>
                        </Grid>
                        <Grid item>
                            <Link href="/login" variant="body2">
                                {"Back to Log In"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}