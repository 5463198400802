import * as Auth from './auth';
import * as Tests from './tests';
import * as Users from './users';
import * as Reports from './reports';

export const API = {
	Auth,
	Tests,
	Users,
	Reports,
};

export default API;