export const getAuthBaseRoute = () => `/auth`;

export const getAuthSignInRoute = () => `${getAuthBaseRoute()}/signin`;

export const getAuthSignOutRoute = () => `${getAuthBaseRoute()}/logout`;

export const getAuthSignUpRoute = () => `${getAuthBaseRoute()}/signup`;

export const getGoogleAuthTokensRoute = () => `${getAuthBaseRoute()}/checkGoogleAuthorizationTokens`;

export const getPasswordResetRequestRoute = () => `${getAuthBaseRoute()}/passwordResetRequest`;

export const getPasswordResetRoute = () => `${getAuthBaseRoute()}/passwordReset`;

export const getResetStatusCheckRoute = () => `${getAuthBaseRoute()}/resetStatusCheck`;