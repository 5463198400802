import React, { useEffect, useReducer, useState } from 'react';
import {
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    FormControl,
    ListItemText,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    withStyles,
    InputAdornment,
    Select,
    InputLabel,
    Snackbar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import './test.component.scss';
import './new-test.component.scss';
import AddCommentIcon from "@material-ui/icons/AddComment";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import _ from "lodash";
import API from '../../api';
import likertScaleResponseSet from './likert-scale-response-set';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
  }));

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props} />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const initialState = {
    id: undefined,
    name: '',
    status: undefined,
    questions: [],
    exportedToGoogle: false,
    questionIndex: -1,

    // Element information
    menuElementText: null,
    menuElementQuestion: null,
    menuElementUngradedQuestion: null,

    // Validation information
    validation: false,
    validImage: true,

    // Rating Scale Select
    ratingScaleSelect: [2,3,4,5,6,7,8,9,10]
}

const likertScaleKeys = Object.keys(likertScaleResponseSet);

function reIndexation(array) {
    const arrayCopy = _.cloneDeep(array);
    for (let i = 0; i < arrayCopy.length; i++) { arrayCopy[i].id = i }
    return arrayCopy;
}

function reArrangeItems(array, from, to) {
    const arrayCopy = _.cloneDeep(array);
    arrayCopy.splice(to, 0, arrayCopy.splice(from, 1)[0]);
    return arrayCopy;
}

function findKeyId(array) {
    let keyIds = [];
    // Create array with existing key ids
    for (let i = 0; i < array.length; i++) {
        keyIds.push(array[i].keyId);
    }
    // Check if there is a missing key id, else return a new one
    for (let i = 0; i < keyIds.length; i++) {
        if (keyIds.indexOf(i) === -1) {
            return i;
        }
    }
    return array.length;
}

function createQuestion(questions, id, questionType) {
    return ({
        id,
        keyId: findKeyId(questions),
        type: questionType,
        link: '',
        question: '',
        booleanAnswer: '',
        selectedLikertScale: '',
        feedbackMessageForCorrectBooleanAnswer: '',
        feedbackMessageForWrongBooleanAnswer: '',
        feedbackMessage: '',
        multipleAnswerList: [
            { key: 0, answer: false, answerText: '', hasFeedback: false, feedbackMessage: '', active: true },
            { key: 1, answer: false, answerText: '', hasFeedback: false, feedbackMessage: '', active: true },
            { key: 2, answer: false, answerText: '', hasFeedback: false, feedbackMessage: '', active: true },
            { key: 3, answer: false, answerText: '', hasFeedback: false, feedbackMessage: '', active: false },
            { key: 4, answer: false, answerText: '', hasFeedback: false, feedbackMessage: '', active: false },
            { key: 5, answer: false, answerText: '', hasFeedback: false, feedbackMessage: '', active: false },
            { key: 6, answer: false, answerText: '', hasFeedback: false, feedbackMessage: '', active: false },
            { key: 7, answer: false, answerText: '', hasFeedback: false, feedbackMessage: '', active: false },
            { key: 8, answer: false, answerText: '', hasFeedback: false, feedbackMessage: '', active: false },
            { key: 9, answer: false, answerText: '', hasFeedback: false, feedbackMessage: '', active: false }
        ],
        answersAmount: 3
    })
}

function setActiveAndAnswerAmount(array) {
    const arrayCopy = _.cloneDeep(array);
    arrayCopy.forEach(question => {
        if (question.type === 'mc' || question.type === 'umc') {
            let amount = 0;
            question.multipleAnswerList.forEach(answer => {
                if (answer.answerText) {
                    answer.active = true;
                    amount++;
                } else {
                    answer.active = false;
                }
            });
            if (amount < 3) {
                for (let i = 0; i < 3; i++) {
                    question.multipleAnswerList[i].active = true;
                }
            }
            question.answersAmount = amount;
        }
    });
    return arrayCopy;
}

function reSortAnswers(array) {
    const arrayCopy = _.cloneDeep(array);
    for (let i = 0; i < arrayCopy.length - 1; i++) {
        const nextItem = arrayCopy[i + 1];
        if (!arrayCopy[i].active) {
            arrayCopy[i + 1] = arrayCopy[i];
            arrayCopy[i] = nextItem;
        }
    }
    return arrayCopy;
}

function reducer(state, action) {
    const currentState = _.cloneDeep(state);
    let { questions } = currentState;
    const currentQuestion = action.data ? (typeof action.data.id !== 'undefined' ? questions.find(question => question.id === action.data.id) : undefined) : undefined;
    switch (action.type) {
        case 'mountUpdate':
            const updatedQuestionsWithKeyId = action.data.questions.map((v, idx) => ({
                ...v,
                keyId: idx
            }));
            const updatedQuestions = setActiveAndAnswerAmount(updatedQuestionsWithKeyId);
            return {
                ...currentState,
                id: action.data.id,
                name: action.data.name,
                status: action.data.status,
                type: action.data.type,
                questions: updatedQuestions,
                questionIndex: action.data.questions.length - 1,
                exportedToGoogle: action.data.exportedToGoogle
            }
        case 'mountNew':
            return {
                ...currentState
            }
        case 'updateName':
            return {
                ...currentState,
                name: action.data.name
            }
        case 'updateLink':
            currentQuestion.link = action.data.value;
            return {
                ...currentState,
                questions
            }
        case 'moveItemUp':
            questions = reIndexation(reArrangeItems(questions, action.data.id, action.data.id - 1));
            return {
                ...currentState,
                questions
            }
        case 'moveItemDown':
            questions = reIndexation(reArrangeItems(questions, action.data.id, action.data.id + 1));
            return {
                ...currentState,
                questions
            }
        case 'questionDelete':
            questions.splice(action.data.id, 1);
            questions = reIndexation(questions);
            return {
                ...currentState,
                questionIndex: state.questionIndex - 1,
                questions
            }
        case 'questionUpdate':
            currentQuestion.question = action.data.value;
            return {
                ...currentState,
                questions
            }
        case 'booleanAnswerUpdate':
            currentQuestion.booleanAnswer = action.data.value === 'true' ? true : false;
            return {
                ...currentState,
                questions
            }
        case 'booleanAnswerFeedbackUpdate':
            if (action.data.correctAnswer === true) {
                currentQuestion.feedbackMessageForCorrectBooleanAnswer = action.data.value;
            } else {
                currentQuestion.feedbackMessageForWrongBooleanAnswer = action.data.value;
            }
            return {
                ...currentState,
                questions
            }
        case 'singleFeedbackUpdate':
            currentQuestion.feedbackMessage = action.data.value;
            return {
                ...currentState,
                questions
            }
        case 'initializeQuestionFeedback':
            if (currentQuestion.type.toLowerCase() === 't/f') {
                currentQuestion.hasFeedback = !currentQuestion.hasFeedback;
                currentQuestion.feedbackMessageForCorrectBooleanAnswer = '';
                currentQuestion.feedbackMessageForWrongBooleanAnswer = '';
            } else if (currentQuestion.type.toLowerCase() === 'mc' || currentQuestion.type.toLowerCase() === 'umc') {
                currentQuestion.multipleAnswerList[action.data.row].hasFeedback = !currentQuestion.multipleAnswerList[action.data.row].hasFeedback;
                currentQuestion.multipleAnswerList[action.data.row].feedbackMessage = '';
            } else if( currentQuestion.type.toLowerCase() === 'ff' || currentQuestion.type.toLowerCase() === 'uff' || currentQuestion.type.toLowerCase() === 'ut/f') {
                currentQuestion.hasFeedback = !currentQuestion.hasFeedback;
                currentQuestion.feedbackMessage = '';
            }
            return {
                ...currentState,
                questions
            }
        case 'multipleAnswerUpdate':
            currentQuestion.multipleAnswerList[action.data.row].answer = action.data.value;
            return {
                ...currentState,
                questions
            }
        case 'multipleAnswerMessageUpdate':
            currentQuestion.multipleAnswerList[action.data.row].answerText = action.data.value;
            return {
                ...currentState,
                questions
            }
        case 'multipleAnswerFeedbackUpdate':
            currentQuestion.multipleAnswerList[action.data.row].feedbackMessage = action.data.value;
            return {
                ...currentState,
                questions
            }
        case 'openMenuText':
            return {
                ...currentState,
                menuElementText: action.data.currentTarget
            }
        case 'openMenuQuestion':
            return {
                ...currentState,
                menuElementQuestion: action.data.currentTarget
            }
        case 'openMenuUngradedQuestion':
            return {
                ...currentState,
                menuElementUngradedQuestion: action.data.currentTarget
            }
        case 'closeMenuText':
            return {
                ...currentState,
                menuElementText: null
            }
        case 'closeMenuQuestion':
            return {
                ...currentState,
                menuElementQuestion: null
            }
        case 'closeMenuUngradedQuestion':
            return {
                ...currentState,
                menuElementUngradedQuestion: null
            }
        case 'likertScaleSelectedUpdate':
            likertScaleResponseSet[action.data.value].forEach((element, idx) => {
                currentQuestion.selectedLikertScale = action.data.value;
                currentQuestion.multipleAnswerList[idx].answerText = element;
                currentQuestion.multipleAnswerList[idx].active = true;
            });
            return {
                ...currentState,
                questions
            }
        case 'ratingScaleSelectedUpdate':
            let lastChoice = '';
            currentQuestion.answersAmount = action.data.value;
            currentQuestion.multipleAnswerList.forEach((answer, idx) => {
                if (idx !== 0 && answer.answerText) {
                    lastChoice = answer.answerText;
                    answer.answerText = '';
                }
                if (answer.key < action.data.value) {
                    answer.active = true;
                } else {
                    answer.active = false;
                }
            });
            currentQuestion.multipleAnswerList[action.data.value - 1].answerText = lastChoice;
            return {
                ...currentState,
                questions
            }
        case 'addNewQuestion':
            return {
                ...currentState,
                questions: [...questions, createQuestion(questions, state.questionIndex + 1, action.data.type)],
                questionIndex: state.questionIndex + 1,
            }
        case 'invalidateStatus':
            return {
                ...currentState,
                validation: true
            }
        case 'validateStatus':
            return {
                ...currentState,
                validation: false
            }
        case 'invalidateImage':
            return {
                ...currentState,
                validImage: false
            }
        case 'validateImage':
            return {
                ...currentState,
                validImage: true
            }
        case 'addMCQuestionAnswer':
            currentQuestion.multipleAnswerList[currentQuestion.answersAmount].active = true;
            currentQuestion.answersAmount++;
            return {
                ...currentState,
            }
        case 'answerDelete':
            if (currentQuestion.answersAmount > 3){
                currentQuestion.multipleAnswerList[action.data.answerId].active = false;
                currentQuestion.multipleAnswerList[action.data.answerId].answer = false;
                currentQuestion.multipleAnswerList[action.data.answerId].answerText = '';
                currentQuestion.multipleAnswerList[action.data.answerId].hasFeedback = false;
                currentQuestion.multipleAnswerList[action.data.answerId].feedbackMessage = '';
                currentQuestion.answersAmount--;
                currentQuestion.multipleAnswerList = reSortAnswers(currentQuestion.multipleAnswerList);
            }
            return {
                ...currentState,
            }
        default:
            return state;
    }
}

export default function NewTestComponent(props) {
    const CHARACTER_LIMIT = 1500;
    const AUTOSAVE_INTERVAL = 3000;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        id,
        name,
        status,
        questions,
        validation,
        validImage,
        menuElementText,
        menuElementQuestion,
        menuElementUngradedQuestion,
        ratingScaleSelect
    } = state;
    const [draftId, setDraftId] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isAutosaved, setIsAutosaved] = useState(false);
    const [autosaveMessage, setAutosaveMessage] = useState('Saved');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const activitySelected = props.location.state ? props.location.state.activitySelected : null;

    const cancelEditing = () => {
        props.history.push('/app/tests/');
    }

    const saveData = async () => {
        let isValid = await isValidDataToSave()
        if (isValid) {
            // checking if we are creating data or editing it
            if (props.match) {
                if (props.match.params.id || draftId) {
                    let { id, name, questions, exportedToGoogle } = state;
                    if (draftId) {
                        id = draftId;
                    }
                    setIsLoading(true);
                    await props.testUpdate(props.history, id, { id, name, questions }, exportedToGoogle);
                    setIsLoading(false);
                } else {
                    const { name, questions } = state;
                    setIsLoading(true);
                    await props.testCreate(props.history, { name, questions });
                    setIsLoading(false);
                }
            }
        } else {
            setOpenSnackbar(true);
        }
    }

    // Function to test if url is a valid image
    function testImage(url) {
        return new Promise(function (resolve, reject) {
            var timeout = 5000;
            var timer, img = new Image();
            img.onerror = img.onabort = function () {
                clearTimeout(timer);
                reject("error");
            };
            img.onload = function () {
                clearTimeout(timer);
                resolve("success");
            };
            timer = setTimeout(function () {
                reject("timeout");
            }, timeout);
            img.src = url;
        });
    }

    async function runImage(url) {
        let response;
        await testImage(url).then((message) => {
            response = true;
        }).catch((message) => {
            dispatch({ type: 'invalidateStatus' });
            response = false;
        })
        return response;
    }

    const isValidDataToSave = async () => {
        if (!state.name) {
            dispatch({ type: 'invalidateStatus' });
            return false;
        }

        for (let i = 0; i < state.questions.length; i++) {
            if (state.questions[i].type.toLowerCase() === 'video') {
                if (!state.questions[i].link) {
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }
            }
            if (state.questions[i].type.toLowerCase() === 'text') {
                if (!state.questions[i].link) {
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }
            }
            if (state.questions[i].type.toLowerCase() === 'image') {
                let url = state.questions[i].link;
                let domain;

                if (!url){
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }

                try {
                    domain = ((new URL(url)).hostname).replace('www.', '');
                } catch(err) {
                    dispatch({ type: 'invalidateImage' });
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }

                if (domain === 'dropbox.com'){
                    url = url.replace('dl=0', 'raw=1')  || '';
                }

                if (domain === 'drive.google.com'){
                    let re = /[-\w]{25,}/;
                    let imageId = url.match(re);
                    if (!imageId){
                        dispatch({ type: 'invalidateImage' });
                        dispatch({ type: 'invalidateStatus' });
                        return false;
                    }
                    imageId = imageId[0];
                    url = `https://drive.google.com/uc?export=view&id=${imageId}`;
                }
                let response = await runImage(url);
                if (!response){
                    dispatch({ type: 'invalidateImage' });
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }
            }
            if (state.questions[i].type.toLowerCase() === 't/f' || state.questions[i].type.toLowerCase() === 'ut/f') {
                if (!state.questions[i].question) {
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }
                if (typeof state.questions[i].booleanAnswer !== 'boolean' && state.questions[i].type.toLowerCase() === 't/f') {
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }
            }
            if (state.questions[i].type.toLowerCase() === 'mc') {
                if (!state.questions[i].question) {
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }
                for (let j = 0; j < state.questions[i].multipleAnswerList.length; j++) {
                    if (state.questions[i].multipleAnswerList[j].active && !state.questions[i].multipleAnswerList[j].answerText) {
                        dispatch({ type: 'invalidateStatus' });
                        return false;
                    }
                }
                if (!state.questions[i].multipleAnswerList.find(answer => answer.answer === true)) {
                    return false;
                }
            }
            if (state.questions[i].type.toLowerCase() === 'umc') {
                if (!state.questions[i].question) {
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }
                for (let j = 0; j < state.questions[i].multipleAnswerList.length; j++) {
                    if (state.questions[i].multipleAnswerList[j].active && !state.questions[i].multipleAnswerList[j].answerText) {
                        dispatch({ type: 'invalidateStatus' });
                        return false;
                    }
                }
            }
            if (state.questions[i].type.toLowerCase() === 'ff') {
                if (!state.questions[i].question) {
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }
            }
            if (state.questions[i].type.toLowerCase() === 'uff') {
                if (!state.questions[i].question) {
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }
            }
            if (state.questions[i].type.toLowerCase() === 'uls') {
                for (let j = 0; j < state.questions[i].multipleAnswerList.length; j++) {
                    if (state.questions[i].multipleAnswerList[j].active && !state.questions[i].multipleAnswerList[j].answerText) {
                        dispatch({ type: 'invalidateStatus' });
                        return false;
                    }
                }
                if (!state.questions[i].question) {
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }
            }
            if (state.questions[i].type.toLowerCase() === 'urs') {
                if (!state.questions[i].multipleAnswerList[0].answerText) {
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }
                if (!state.questions[i].multipleAnswerList[state.questions[i].answersAmount - 1].answerText) {
                    dispatch({ type: 'invalidateStatus' });
                    return false;
                }
            }
        }
        return true;
    }

    useEffect(() => {
        let mounted = true;
        if (props.match.params.id) {
            API.Tests.getTests(props.match.params.id).then(({ data }) => {
                if(mounted) {
                    dispatch({ type: 'mountUpdate', data });
                }
            });
        } else {
            dispatch({ type: 'mountNew' });
        }
        return () => mounted = false;
    }, [props.match.params.id]);

    useEffect(() => {
        let mounted = true;
        const timer = setTimeout(() => {
            async function updateDraft() {
                const testId = id ? id : draftId;
                const draftData = {
                    'id': testId,
                    'name': name,
                    'questions': questions
                };
                const response = await API.Tests.draftTest(draftData);
                if (mounted) {
                    setIsAutosaved(true);
                    if (response.status === 200) {
                        setDraftId(response.data.id);
                        setAutosaveMessage('Saved');
                    } else {
                        setAutosaveMessage('Error in Autosave');
                    }
                }
            }
            if (mounted && name && status !== 'published') {
                setAutosaveMessage('Saving...');
                updateDraft();
            }
        }, AUTOSAVE_INTERVAL);
        return () => {
            clearTimeout(timer);
            mounted = false;
        }
    }, [id, draftId, name, status, questions]);

    const loadingIcon = <AutorenewIcon className="icon-spin icon-margin" />;

    const handleCloseSnackbar = () =>{
        setOpenSnackbar(false);
    }

    const classes = useStyles();
    return (
        <div className='cast-card cast-card--init'>
            <div className='cast-form'>
                <div className='cast-form__title'>
                    {
                        activitySelected === 'learning' ? name || 'New Learning Activity' 
                        : name || 'New Survey Activity'
                    }
                    {
                        isAutosaved && (
                            <div className='cast-form__labels'>
                                <Chip
                                    icon={<QueryBuilderIcon />}
                                    label={autosaveMessage}
                                    color="secondary"
                                />
                            </div>
                        )
                    }
                </div>
                <div className='cast-form__section'>
                    <TextField value={name} autoFocus multiline
                        error={!name && validation}
                        helperText={!name && validation ? 'Invalid value' : null }
                        onChange={(e) => dispatch({ type: 'updateName', data: { name: e.target.value } })}
                        className='cast-form__field' label="Activity Name" variant="outlined" />
                </div>
                <div id='form-content'>
                    {questions.map(question => {
                        if (question.type.toLowerCase() === 'video') {
                            return (
                                <div key={question.keyId} id={question.id}
                                    className='cast-form__section cast-form-rearrange-box'>
                                    <div className='cast-form__field'>
                                        <TextField value={question.link}
                                            multiline
                                            error={!question.link && validation}
                                            helperText={!question.link && validation ? 'Invalid value' : null }
                                            onChange={(e) => dispatch({ type: 'updateLink', data: { value: e.target.value, id: question.id } })}
                                            label="Video Link"
                                            variant="outlined"
                                            placeholder="Example: https://www.youtube.com/watch?v=WIsSPUVftaI">
                                        </TextField>
                                        <svg className='cast-form__icon'
                                            onClick={() => dispatch({ type: 'questionDelete', data: { id: question.id } })} width={24}
                                            height={24} viewBox="0 0 24 24">
                                            <path
                                                d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM6.984 11.016h10.031v1.969h-10.031v-1.969z" />
                                        </svg>
                                    </div>
                                    <div className={questions.length === 1 ? null : 'cast-form-rearrange'}>
                                        {question.id > 0 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemUp', data: { id: question.id } })}>
                                                <KeyboardArrowUpIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                        {question.id < questions.length - 1 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemDown', data: { id: question.id } })}>
                                                <KeyboardArrowDownIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                    </div>
                                </div>
                            )
                        } else if (question.type.toLowerCase() === 'image') {
                            return (
                                <div key={question.keyId} id={question.id}
                                    className='cast-form__section cast-form-rearrange-box'>
                                    <div className='cast-form__field'>
                                        <TextField value={question.link}
                                            multiline
                                            error={(!question.link && validation) || !validImage}
                                            helperText={!question.link && validation ? 'Invalid value' : null }
                                            onChange={(e) => {
                                                dispatch({ type: 'updateLink', data: { value: e.target.value, id: question.id } });
                                                dispatch({ type: 'validateImage' })
                                                }
                                            }
                                            label="Image Link"
                                            variant="outlined"
                                            placeholder='Note: If using your own image files, they must be uploaded to cloud storage (Google Drive, OneDrive, Dropbox, etc.). Shareable image link permissions must be "Anyone with link".'>
                                        </TextField>
                                        {
                                            validImage ?
                                                <span />
                                                :
                                                <div className='cast-form__correct-answer-message'>
                                                    <span>This URL does not appear to contain a valid image</span>
                                                </div>
                                        }
                                        <svg className='cast-form__icon'
                                            onClick={() => dispatch({ type: 'questionDelete', data: { id: question.id } })} width={24}
                                            height={24} viewBox="0 0 24 24">
                                            <path
                                                d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM6.984 11.016h10.031v1.969h-10.031v-1.969z" />
                                        </svg>
                                    </div>
                                    <div className={questions.length === 1 ? null : 'cast-form-rearrange'}>
                                        {question.id > 0 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemUp', data: { id: question.id } })}>
                                                <KeyboardArrowUpIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                        {question.id < questions.length - 1 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemDown', data: { id: question.id } })}>
                                                <KeyboardArrowDownIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                    </div>
                                </div>
                            )
                        } else if (question.type.toLowerCase() === 'text') {
                            return (
                                <div key={question.keyId} id={question.id}
                                    className='cast-form__section cast-form-rearrange-box'>
                                    <div className='cast-form__field'>
                                        <TextField value={question.link}
                                            multiline
                                            error={!question.link && validation}
                                            helperText={!question.link && validation ? 'Invalid value' : question.link.length > 1250 ? `${question.link.length}/${CHARACTER_LIMIT}` : null }
                                            onChange={(e) => dispatch({ type: 'updateLink', data: { value: e.target.value, id: question.id } })}
                                            label="Text"
                                            variant="outlined"
                                            placeholder="Text, links to websites or other resources, including files like PDF, Word Documents, Spreadsheets. Files must be stored on cloud storage and a shareable link created."
                                            inputProps={{maxLength: CHARACTER_LIMIT}} />
                                        <svg className='cast-form__icon'
                                            onClick={() => dispatch({ type: 'questionDelete', data: { id: question.id } })} width={24}
                                            height={24} viewBox="0 0 24 24">
                                            <path
                                                d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM6.984 11.016h10.031v1.969h-10.031v-1.969z" />
                                        </svg>
                                    </div>
                                    <div className={questions.length === 1 ? null : 'cast-form-rearrange'}>
                                        {question.id > 0 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemUp', data: { id: question.id } })}>
                                                <KeyboardArrowUpIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                        {question.id < questions.length - 1 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemDown', data: { id: question.id } })}>
                                                <KeyboardArrowDownIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                    </div>
                                </div>
                            )
                        } else if (question.type.toLowerCase() === 't/f' || question.type.toLowerCase() === 'ut/f') {
                            return (
                                <div key={question.keyId} id={question.id}
                                    className='cast-form__section cast-form-rearrange-box'>
                                    <div className='cast-form__field'>
                                        <TextField value={question.question}
                                            multiline
                                            error={(!question.question && validation) || (typeof question.booleanAnswer !== 'boolean' && validation && question.type === "t/f")}
                                            helperText={validation ? (!question.question ? 'Invalid value' : 'Invalid answer') : question.question.length > 1250 ? `${question.question.length}/${CHARACTER_LIMIT}` : null }
                                            onChange={(e) => dispatch({ type: 'questionUpdate', data: { value: e.target.value, id: question.id } })}
                                            label={question.type.toLowerCase() === 't/f' ? 'True/False Question' : 'Ungraded True/False Question'}
                                            variant="outlined"
                                            inputProps={{maxLength: CHARACTER_LIMIT}} />
                                        {question.type.toLowerCase() === 't/f' ?
                                            <RadioGroup className='cast-form__radio-group' aria-label="gender"
                                                name="gender1" value={question.booleanAnswer.toString()}
                                                onChange={(e) => dispatch({ type: 'booleanAnswerUpdate', data: { value: e.target.value, id: question.id } })}>
                                                <div>Answer:</div>
                                                <FormControlLabel value="true" control={<Radio color="primary" />}
                                                    label="True" />
                                                <FormControlLabel value="false" control={<Radio color="primary" />}
                                                    label="False" />
                                            </RadioGroup>
                                        : null }
                                        <svg className='cast-form__icon'
                                            onClick={() => dispatch({ type: 'questionDelete', data: { id: question.id } })} width={24}
                                            height={24} viewBox="0 0 24 24">
                                            <title>Delete Question</title>
                                            <path
                                                d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM6.984 11.016h10.031v1.969h-10.031v-1.969z" />
                                        </svg>
                                    </div>
                                    <div className='cast-form__feedback'>
                                        {question.hasFeedback ? (
                                            <div className='cast-form__feedback-box'>
                                                {question.type.toLowerCase() === 'ut/f' ?
                                                    <TextField
                                                        className='cast-form__feedback-message'
                                                        multiline
                                                        label="Add Feedback"
                                                        value={question.feedbackMessage}
                                                        onChange={(e) => dispatch({ type: 'singleFeedbackUpdate', data: { id: question.id, value: e.target.value } })}
                                                        variant="outlined"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <AddCommentIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                    :
                                                    <div className='cast-form__feedback-box'>
                                                        <TextField
                                                            className='cast-form__feedback-message'
                                                            multiline
                                                            label="Feedback For Correct Answer"
                                                            value={question.feedbackMessageForCorrectBooleanAnswer}
                                                            onChange={(e) => dispatch({ type: 'booleanAnswerFeedbackUpdate', data: { id: question.id, correctAnswer: true, value: e.target.value } })}
                                                            variant="outlined"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AddCommentIcon />
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                        <TextField
                                                            label="Feedback For Incorrect Answer"
                                                            multiline
                                                            value={question.feedbackMessageForWrongBooleanAnswer}
                                                            onChange={(e) => dispatch({ type: 'booleanAnswerFeedbackUpdate', data: { id: question.id, correctAnswer: false, value: e.target.value } })}
                                                            variant="outlined"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AddCommentIcon />
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                <svg className='cast-form__icon'
                                                    onClick={() => dispatch({ type: 'initializeQuestionFeedback', data: { id: question.id } })}
                                                    width={24}
                                                    height={24} viewBox="0 0 24 24">
                                                    <title>Delete Feedback</title>
                                                    <path
                                                        d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM6.984 11.016h10.031v1.969h-10.031v-1.969z" />
                                                </svg>
                                            </div>
                                        ) : (
                                            <div onClick={() => dispatch({ type: 'initializeQuestionFeedback', data: { id: question.id } })}
                                                className='cast-form__feedback-icon'>
                                                <svg id="icon-add_comment" width={52} height={52}
                                                    viewBox="0 0 24 24">
                                                    <title>Add Feedback</title>
                                                    <path
                                                        d="M17.016 11.016v-2.016h-4.031v-3.984h-1.969v3.984h-4.031v2.016h4.031v3.984h1.969v-3.984h4.031zM21.984 3.984v18l-3.984-3.984h-14.016q-0.797 0-1.383-0.609t-0.586-1.406v-12q0-0.797 0.586-1.383t1.383-0.586h16.031q0.797 0 1.383 0.586t0.586 1.383z" />
                                                </svg>
                                                <span>Add Feedback</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className={questions.length === 1 ? null : 'cast-form-rearrange'}>
                                        {question.id > 0 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemUp', data: { id: question.id } })}>
                                                <KeyboardArrowUpIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                        {question.id < questions.length - 1 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemDown', data: { id: question.id } })}>
                                                <KeyboardArrowDownIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                    </div>
                                </div>
                            )
                        } else if (question.type.toLowerCase() === 'mc' || question.type.toLowerCase() === 'umc') {
                            return (
                                <div key={question.keyId} id={question.id}
                                    className='cast-form__section cast-form-rearrange-box'>
                                    <div className='cast-form__field'>
                                        <TextField value={question.question}
                                            multiline
                                            error={!question.question && validation}
                                            helperText={!question.question && validation ? 'Invalid value' : question.question.length > 1250 ? `${question.question.length}/${CHARACTER_LIMIT}` : null }
                                            onChange={(e) => dispatch({ type: 'questionUpdate', data: { value: e.target.value, id: question.id } })}
                                            className='cast-form__field'
                                            label={question.type.toLowerCase() === 'mc' ? 'Multiple Choice Question' : 'Ungraded Multiple Choice Question'}
                                            variant="outlined"
                                            inputProps={{maxLength: CHARACTER_LIMIT}} />
                                        {question.multipleAnswerList.map((multipleQuestion, idx) => {
                                            if (multipleQuestion.active) {
                                                return (
                                                    <div key={multipleQuestion.key} className='cast-form__box'>
                                                        {question.type.toLowerCase() === 'mc' ? (
                                                            <Checkbox checked={multipleQuestion.answer}
                                                            onChange={(e) => dispatch({ type: 'multipleAnswerUpdate', data: { id: question.id, row: idx, value: e.target.checked } })}
                                                            className='cast-form__checkbox'
                                                            inputProps={{ 'aria-label': 'uncontrolled-checkbox' }}
                                                            color="primary" />
                                                        ) : null }
                                                        <div className='cast-form__box-answer'>
                                                           <TextField value={multipleQuestion.answerText}
                                                                multiline
                                                                error={!multipleQuestion.answerText && validation}
                                                                helperText={!multipleQuestion.answerText && validation ? 'Invalid value' : multipleQuestion.answerText.length > 1250 ? `${multipleQuestion.answerText.length}/${CHARACTER_LIMIT}` : null }
                                                                onChange={(e) => dispatch({ type: 'multipleAnswerMessageUpdate', data: { id: question.id, row: idx, value: e.target.value } })}
                                                                className='cast-form__field' label="Answer"
                                                                variant="outlined"
                                                                inputProps={{maxLength: CHARACTER_LIMIT}} />
                                                            {question.answersAmount > 3 ?
                                                            <svg className='cast-form__icon_answer'
                                                                onClick={() => dispatch({ type: 'answerDelete', data: { id: question.id, answerId: idx } })} width={24}
                                                                height={24} viewBox="0 0 24 24">
                                                                <title>Delete Answer</title>
                                                                <path
                                                                    d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM6.984 11.016h10.031v1.969h-10.031v-1.969z" />
                                                            </svg>
                                                            : null }
                                                        </div>
                                                        <div className='cast-form__feedback'>
                                                            {multipleQuestion.hasFeedback ? (
                                                                <div className='cast-form__feedback-box'>
                                                                    <TextField
                                                                        className='cast-form__feedback-message'
                                                                        multiline
                                                                        label="Add Feedback"
                                                                        value={multipleQuestion.feedbackMessage}
                                                                        onChange={(e) => dispatch({ type: 'multipleAnswerFeedbackUpdate', data: { id: question.id, row: idx, value: e.target.value } })}
                                                                        variant="outlined"
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    <AddCommentIcon />
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                    <svg className='cast-form__icon'
                                                                        onClick={() => dispatch({ type: 'initializeQuestionFeedback', data: { id: question.id, row: idx } })}
                                                                        width={24}
                                                                        height={24} viewBox="0 0 24 24">
                                                                        <title>Delete Feedback</title>
                                                                        <path
                                                                            d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM6.984 11.016h10.031v1.969h-10.031v-1.969z" />
                                                                    </svg>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    onClick={() => dispatch({ type: 'initializeQuestionFeedback', data: { id: question.id, row: idx } })}
                                                                    className='cast-form__feedback-icon'>
                                                                    <svg id="icon-add_comment" width={52} height={52}
                                                                        viewBox="0 0 24 24">
                                                                        <title>Add Feedback</title>
                                                                        <path
                                                                            d="M17.016 11.016v-2.016h-4.031v-3.984h-1.969v3.984h-4.031v2.016h4.031v3.984h1.969v-3.984h4.031zM21.984 3.984v18l-3.984-3.984h-14.016q-0.797 0-1.383-0.609t-0.586-1.406v-12q0-0.797 0.586-1.383t1.383-0.586h16.031q0.797 0 1.383 0.586t0.586 1.383z" />
                                                                    </svg>
                                                                    <span>Add Feedback</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return null
                                            }
                                        })}
                                        {(question.type.toLowerCase() === 'mc' && question.answersAmount >= 3 && question.answersAmount < 5) || (question.type.toLowerCase() === 'umc' && question.answersAmount >= 1 && question.answersAmount < 10) ?
                                            <div className='cast-form__box-add-answer'>
                                                <div onClick={() => dispatch({ type: 'addMCQuestionAnswer', data: { id: question.id } })}
                                                    className='cast-form__add-answer-icon'>
                                                    <svg id="icon-add_answer" width={30} height={30}
                                                        viewBox="0 0 24 24">
                                                        <title>Add Answer</title>
                                                        <path
                                                            d="M18 13.496h-4.501v4.484h-3v-4.484H6v-2.99h4.5V6.021h3.001v4.485H18v2.99zM21 .041H3C1.348.043.008 1.379 0 3.031v17.94c.008 1.65 1.348 2.986 3 2.988h18c1.651-.002 2.991-1.338 3-2.988V3.031c-.009-1.652-1.348-2.987-3-2.99z" />
                                                    </svg>
                                                    <span>Add Answer</span>
                                                </div>
                                            </div>
                                        : null }
                                        {question.type.toLowerCase() === 'mc' ?
                                         question.multipleAnswerList.find(answer => answer.answer === true) ?
                                            <div className='cast-form__correct-answer-message-tip'>
                                                <span>TIP: You can select more than one answer!</span>
                                            </div>
                                        :
                                            <div className='cast-form__correct-answer-message'>
                                                <span>You must select at least one correct answer</span>
                                            </div>
                                        : null}
                                        <svg className='cast-form__icon'
                                            onClick={() => dispatch({ type: 'questionDelete', data: { id: question.id } })} width={24}
                                            height={24} viewBox="0 0 24 24">
                                            <title>Delete Question</title>
                                            <path
                                                d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM6.984 11.016h10.031v1.969h-10.031v-1.969z" />
                                        </svg>
                                    </div>
                                    <div className={questions.length === 1 ? null : 'cast-form-rearrange'}>
                                        {question.id > 0 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemUp', data: { id: question.id } })}>
                                                <KeyboardArrowUpIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                        {question.id < questions.length - 1 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemDown', data: { id: question.id } })}>
                                                <KeyboardArrowDownIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                    </div>
                                </div>
                            )
                        } else if (question.type.toLowerCase() === 'uls') {
                            return (
                                <div key={question.keyId} id={question.id}
                                    className='cast-form__section cast-form-rearrange-box'>
                                    <div className='cast-form__field'>
                                        <TextField value={question.question}
                                            multiline
                                            error={!question.question && validation}
                                            helperText={!question.question && validation ? 'Invalid value' : question.question.length > 1250 ? `${question.question.length}/${CHARACTER_LIMIT}` : null }
                                            onChange={(e) => dispatch({ type: 'questionUpdate', data: { value: e.target.value, id: question.id } })}
                                            className='cast-form__field'
                                            label='Likert Scale question'
                                            variant="outlined"
                                            inputProps={{maxLength: CHARACTER_LIMIT}}
                                        />
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id='likert-scale-ungraded-input'>Select scale type</InputLabel>
                                            <Select
                                                error={!question.selectedLikertScale && validation}
                                                helperText='You must select an option'
                                                labelId="likert-scale-ungraded-input"
                                                value={question.selectedLikertScale ?? question.selectedLikertScale}
                                                id="likert-scale-ungraded-select"
                                            >
                                            {likertScaleKeys.map((key, idx) =>{
                                                return(
                                                    <MenuItem
                                                        key={idx}
                                                        value={key}
                                                        onClick={() => {
                                                            dispatch({ type: 'closeMenuText' })
                                                            dispatch({ type: 'closeMenuUngradedLikertScale' })
                                                            dispatch({ type: 'likertScaleSelectedUpdate', data: { value: key, id: question.id } })
                                                    }}>
                                                        {key}
                                                    </MenuItem>
                                                )
                                            })
                                            }
                                            </Select>
                                            {!question.selectedLikertScale && validation ? 
                                                <span style={{ marginTop:'5px', color:'red', fontSize:'12px'}}>Select an option</span>
                                                : null
                                            }
                                        </FormControl>
                                        <div className="likert_scale_selected_answers">
                                            {question.selectedLikertScale ?
                                                question.multipleAnswerList.map((element, idx) => {
                                                    if (element.active){
                                                        return (
                                                            <div key={`${element.answerText}_${idx}`} className='cast-form__box'>
                                                                <div className='cast-form__box-answer'>
                                                                <TextField value={element.answerText}
                                                                        multiline
                                                                        className='cast-form__field likert-scale-answers' label="Answer"
                                                                        variant="outlined"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    } else return null
                                                    }
                                                )
                                                : null
                                            }
                                        </div>
                                        <svg className='cast-form__icon'
                                            onClick={() => {dispatch({ type: 'questionDelete', data: { id: question.id } })}}
                                            width={24}
                                            height={24} viewBox="0 0 24 24">
                                            <title>Delete Question</title>
                                            <path
                                                d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM6.984 11.016h10.031v1.969h-10.031v-1.969z" />
                                        </svg>
                                    </div>
                                    <div className={questions.length === 1 ? null : 'cast-form-rearrange'}>
                                        {question.id > 0 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemUp', data: { id: question.id } })}>
                                                <KeyboardArrowUpIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                        {question.id < questions.length - 1 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemDown', data: { id: question.id } })}>
                                                <KeyboardArrowDownIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                    </div>
                                </div>
                            )
                        } else if (question.type.toLowerCase() === 'urs') {
                            return (
                                <div key={question.keyId} id={question.id}
                                    className='cast-form__section cast-form-rearrange-box'>
                                    <div className='cast-form__field'>
                                        <div className='cast-form__rating-scale'>
                                            <span>On a scale of 1 to </span>
                                            <Select
                                                value={question.answersAmount}
                                                onChange={(e) => dispatch({ type: 'ratingScaleSelectedUpdate', data: { value: e.target.value, id: question.id } })}
                                            >
                                                {ratingScaleSelect.map(element => {
                                                    return(
                                                        <MenuItem key={element} value={element}>{element}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            <span> where 1 is </span>
                                            <TextField style = {{width: 120}}
                                                error={!question.multipleAnswerList[0].answerText && validation}
                                                helperText={!question.multipleAnswerList[0].answerText && validation ? 'Invalid value' : null }
                                                value={question.multipleAnswerList[0].answerText}
                                                onChange={(e) => dispatch({ type: 'multipleAnswerMessageUpdate', data: { id: question.id, row: 0, value: e.target.value } })}
                                            />
                                            <span>and {question.answersAmount} is </span>
                                            <TextField style = {{width: 120}}
                                                error={!question.multipleAnswerList[question.answersAmount - 1].answerText && validation}
                                                helperText={!question.multipleAnswerList[question.answersAmount - 1].answerText && validation ? 'Invalid value' : null }
                                                value={question.multipleAnswerList[question.answersAmount - 1].answerText}
                                                onChange={(e) => dispatch({ type: 'multipleAnswerMessageUpdate', data: { id: question.id, row: question.answersAmount - 1, value: e.target.value } })}
                                            />
                                        </div>
                                        <TextField value={question.question}
                                            multiline
                                            error={!question.question && validation}
                                            helperText={!question.question && validation ? 'Invalid value' : question.question.length > 1250 ? `${question.question.length}/${CHARACTER_LIMIT}` : null }
                                            onChange={(e) => dispatch({ type: 'questionUpdate', data: { value: e.target.value, id: question.id } })}
                                            className='cast-form__field'
                                            label='Rating Scale question'
                                            variant="outlined"
                                            inputProps={{maxLength: CHARACTER_LIMIT}}
                                        />
                                        <svg className='cast-form__icon'
                                            onClick={() => {dispatch({ type: 'questionDelete', data: { id: question.id } })}}
                                            width={24}
                                            height={24} viewBox="0 0 24 24">
                                            <title>Delete Question</title>
                                            <path
                                                d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM6.984 11.016h10.031v1.969h-10.031v-1.969z" />
                                        </svg>
                                    </div>
                                    <div className={questions.length === 1 ? null : 'cast-form-rearrange'}>
                                        {question.id > 0 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemUp', data: { id: question.id } })}>
                                                <KeyboardArrowUpIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                        {question.id < questions.length - 1 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemDown', data: { id: question.id } })}>
                                                <KeyboardArrowDownIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                    </div>
                                </div>
                            )
                        } else if (question.type.toLowerCase() === 'ff' || question.type.toLowerCase() === 'uff') {
                            return (
                                <div key={question.keyId} id={question.id}
                                    className='cast-form__section cast-form-rearrange-box'>
                                    <div className='cast-form__field'>
                                        <TextField value={question.question}
                                            multiline
                                            error={!question.question && validation}
                                            helperText={!question.question && validation ? 'Invalid value' : question.question.length > 1250 ? `${question.question.length}/${CHARACTER_LIMIT}` : null }
                                            onChange={(e) => dispatch({ type: 'questionUpdate', data: { value: e.target.value, id: question.id } })}
                                            label={question.type.toLowerCase() === 'ff' ? 'Free Form Question' : 'Open-ended Question'}
                                            variant="outlined"
                                            inputProps={{maxLength: CHARACTER_LIMIT}} />
                                        <svg className='cast-form__icon'
                                            onClick={() => dispatch({ type: 'questionDelete', data: { id: question.id } })} width={24}
                                            height={24} viewBox="0 0 24 24">
                                            <path
                                                d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM6.984 11.016h10.031v1.969h-10.031v-1.969z" />
                                        </svg>
                                    </div>
                                    <div className='cast-form__feedback'>
                                        {question.hasFeedback ? (
                                            <div className='cast-form__feedback-box'>
                                                <TextField
                                                    className='cast-form__feedback-message'
                                                    multiline
                                                    label="Add Feedback"
                                                    value={question.feedbackMessage}
                                                    onChange={(e) => dispatch({ type: 'singleFeedbackUpdate', data: { id: question.id, value: e.target.value } })}
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AddCommentIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                <svg className='cast-form__icon'
                                                    onClick={() => dispatch({ type: 'initializeQuestionFeedback', data: { id: question.id, row: question.key } })}
                                                    width={24}
                                                    height={24} viewBox="0 0 24 24">
                                                    <title>Delete Feedback</title>
                                                    <path
                                                        d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM6.984 11.016h10.031v1.969h-10.031v-1.969z" />
                                                </svg>
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => dispatch({ type: 'initializeQuestionFeedback', data: { id: question.id, row: question.key } })}
                                                className='cast-form__feedback-icon'>
                                                <svg id="icon-add_comment" width={52} height={52}
                                                    viewBox="0 0 24 24">
                                                    <title>Add Feedback</title>
                                                    <path
                                                        d="M17.016 11.016v-2.016h-4.031v-3.984h-1.969v3.984h-4.031v2.016h4.031v3.984h1.969v-3.984h4.031zM21.984 3.984v18l-3.984-3.984h-14.016q-0.797 0-1.383-0.609t-0.586-1.406v-12q0-0.797 0.586-1.383t1.383-0.586h16.031q0.797 0 1.383 0.586t0.586 1.383z" />
                                                </svg>
                                                <span>Add Feedback</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className={questions.length === 1 ? null : 'cast-form-rearrange'}>
                                        {question.id > 0 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemUp', data: { id: question.id } })}>
                                                <KeyboardArrowUpIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                        {question.id < questions.length - 1 ?
                                            <div className={question.id === 0 || question.id === questions.length - 1 ? 'cast-form-rearrange-direction-single' : 'cast-form-rearrange-direction'}
                                                onClick={() => dispatch({ type: 'moveItemDown', data: { id: question.id } })}>
                                                <KeyboardArrowDownIcon className='cast-form__arrow'/>
                                            </div>
                                        : null }
                                    </div>
                                </div>
                            )
                        } else return null;
                    })}
                </div>
                <div className='cast-form__action-group'>
                    <Button 
                        className='cast-form__action' 
                        aria-controls="customized-menu-text"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={(e) => dispatch({ type: 'openMenuText', data: { currentTarget: e.currentTarget } })}>
                        Add Content
                        </Button>
                    <StyledMenu
                        id="customized-menu-text"
                        anchorEl={menuElementText}
                        keepMounted
                        open={Boolean(menuElementText)}
                        onClose={() => dispatch({ type: 'closeMenuText' })}>
                        <StyledMenuItem onClick={() => {
                            dispatch({ type: 'addNewQuestion', data: { type: 'video' } })
                            dispatch({ type: 'closeMenuText' })
                            dispatch({ type: 'closeMenuQuestion' })
                        }}>
                            <ListItemText primary="Add Video" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => {
                            dispatch({ type: 'addNewQuestion', data: { type: 'image' } })
                            dispatch({ type: 'closeMenuText' })
                            dispatch({ type: 'closeMenuQuestion' })
                        }}>
                            <ListItemText primary="Add Image" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => {
                            dispatch({ type: 'addNewQuestion', data: { type: 'text' } })
                            dispatch({ type: 'closeMenuText' })
                            dispatch({ type: 'closeMenuQuestion' })
                        }}>
                            <ListItemText primary="Add Text" />
                        </StyledMenuItem>
                    </StyledMenu>
                    {
                        state.type === 'learning' || state.type === 'mixed' || activitySelected === 'learning' ?
                    <>
                        <Button
                            className='cast-form__action'
                            aria-controls="customized-menu-question"
                            aria-haspopup="true"
                            variant="contained"
                            color="primary"
                            onClick={(e) => dispatch({ type: 'openMenuQuestion', data: { currentTarget: e.currentTarget } })}>
                            Add Question
                        </Button>
                        <StyledMenu
                            id="customized-menu-question"
                            anchorEl={menuElementQuestion}
                            keepMounted
                            open={Boolean(menuElementQuestion)}
                            onClose={() => dispatch({ type: 'closeMenuQuestion' })}>
                            <StyledMenuItem onClick={() => {
                                dispatch({ type: 'addNewQuestion', data: { type: 't/f' } })
                                dispatch({ type: 'closeMenuText' })
                                dispatch({ type: 'closeMenuQuestion' })
                            }}>
                                <ListItemText primary="True/False" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => {
                                dispatch({ type: 'addNewQuestion', data: { type: 'mc' } })
                                dispatch({ type: 'closeMenuText' })
                                dispatch({ type: 'closeMenuQuestion' })
                            }}>
                                <ListItemText primary="Multiple Choice" />
                            </StyledMenuItem>
                            <StyledMenuItem onClick={() => {
                                dispatch({ type: 'addNewQuestion', data: { type: 'ff' } })
                                dispatch({ type: 'closeMenuText' })
                                dispatch({ type: 'closeMenuQuestion' })
                            }}>
                                <ListItemText primary="Free Form" />
                            </StyledMenuItem>
                        </StyledMenu>
                    </>
                    : null
                    }
                    {
                        state.type === 'survey' || state.type === 'mixed' || activitySelected === 'survey' ?
                    <>
                    <Button
                        className='cast-form__action'
                        aria-controls="customized-menu-ungraded-question"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={(e) => dispatch({ type: 'openMenuUngradedQuestion', data: { currentTarget: e.currentTarget } })}>
                        Add Question
                    </Button>
                    <StyledMenu
                        id="customized-menu-ungraded-question"
                        anchorEl={menuElementUngradedQuestion}
                        keepMounted
                        open={Boolean(menuElementUngradedQuestion)}
                        onClose={() => dispatch({ type: 'closeMenuUngradedQuestion' })}>
                        <StyledMenuItem onClick={() => {
                            dispatch({ type: 'addNewQuestion', data: { type: 'ut/f' } })
                            dispatch({ type: 'closeMenuText' })
                            dispatch({ type: 'closeMenuUngradedQuestion' })
                        }}>
                            <ListItemText primary="True/False" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => {
                            dispatch({ type: 'addNewQuestion', data: { type: 'umc' } })
                            dispatch({ type: 'closeMenuText' })
                            dispatch({ type: 'closeMenuUngradedQuestion' })
                        }}>
                            <ListItemText primary="Multiple Choice" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => {
                            dispatch({ type: 'addNewQuestion', data: { type: 'uff' } })
                            dispatch({ type: 'closeMenuText' })
                            dispatch({ type: 'closeMenuUngradedQuestion' })
                        }}>
                            <ListItemText primary="Open-ended" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => {
                            dispatch({ type: 'addNewQuestion', data: { type: 'uls' } })
                            dispatch({ type: 'closeMenuText' })
                            dispatch({ type: 'closeMenuUngradedQuestion' })
                        }}>
                            <ListItemText primary="Likert Scale" />
                        </StyledMenuItem>
                        <StyledMenuItem onClick={() => {
                            dispatch({ type: 'addNewQuestion', data: { type: 'urs' } })
                            dispatch({ type: 'closeMenuText' })
                            dispatch({ type: 'closeMenuUngradedQuestion' })
                        }}>
                            <ListItemText primary="Rating Scale" />
                        </StyledMenuItem>
                    </StyledMenu>
                    </>
                    : null
                    }
                </div>
            </div>
            <div className='cast-card__action-group'>
                <Button onClick={() => cancelEditing()} className='cast-card__action' variant="contained" disabled={isLoading}> 
                    Cancel
                    </Button>
                <Button onClick={() => saveData()} className='cast-card__action' variant="contained" disabled={isLoading}
                    color="primary">
                    {isLoading ? loadingIcon : null}
                    Publish
                </Button>
            </div>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert severity="error">There is an error! Please verify.</Alert>
            </Snackbar>
        </div >
    );
}