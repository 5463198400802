import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, TextField, Table, TableBody, TableCell, TableRow, FormLabel,
         Radio, RadioGroup, FormControlLabel, FormControl, FormHelperText,
         Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
         LinearProgress } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import API from '../../api';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    icon: {
        fontSize: '18px',
        margin: 'auto'
    },
    iconContainer:{
        width: '30px',
        height: '30px',
        display: 'inline-flex'
    }
}));


export function AnswerIcon(props) {
    const { icon } = props;
    if (icon === 'correct') {
        return (
            <CheckIcon className='cast-form__correct_answer'/>
        )
    } else if (icon === 'incorrect') {
        return (
            <CloseIcon className='cast-form__incorrect_answer'/>
        )
    } else if (icon === 'hidden') {
        return (
            <CloseIcon className='cast-form__hidden_answer'/>
        )
    } else if (icon === 'ungraded') {
        return (
            <CheckIcon className='cast-form__ungraded_answer'/>
        )
    }
}

export function GradingFreeForm(props) {
    const { report, question } = props;
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setHelperText('');
        setError(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (value === 'C' || value === 'I') {
          setHelperText('');
          setError(false);
          setOpen(true);
        } else {
          setHelperText('Please select an option.');
          setError(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleIsLoading = () => {
        setIsLoading(true)
    }

    const handleIsNotLoading = () => {
        setIsLoading(false)
    }

    const handleGradeQuestion = async () => {
        handleIsLoading();
        const body = {};
        if (value === 'C') {
            body.grade = true;
        } else if (value === 'I') {
            body.grade = false;
        }
        await API.Reports.gradeReport(report, question, body);
        props.fetchData();
        handleIsNotLoading();
        handleClose();
    };

    const classes = useStyles();

    return (
        <div className='cast-form__ff-grading-box'>
            <form onSubmit={handleSubmit}>
                <FormControl className='cast-form__ff-grading' component="fieldset" error={error} >
                    <FormLabel component="legend">This answer is not yet graded</FormLabel>
                    <RadioGroup row aria-label="ff-grading" name="ff-grading" value={value} onChange={handleRadioChange}>
                        <FormControlLabel value="C" control={<Radio color='primary'/>} label="Correct" />
                        <FormControlLabel value="I" control={<Radio color='primary'/>} label="Incorrect" />
                    </RadioGroup>
                    <FormHelperText>{helperText}</FormHelperText>
                    <Button type="submit" variant="contained" color="primary">
                        Grade Answer
                    </Button>
                </FormControl>
            </form>
            <Dialog open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm">
                <DialogTitle id="alert-dialog-title">Grade Free Form Question</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        After grading all the free form questions we will calculate the final score for this report.
                    </DialogContentText>
                    <DialogContentText>
                        Do you want to grade this question as {value === 'C' ? 'correct' : 'incorrect'}?
                    </DialogContentText>
                </DialogContent>
                {
                    !isLoading ?
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleGradeQuestion} color="primary" autoFocus>
                                Grade
                            </Button>
                        </DialogActions>
                        :
                        <div className={classes.root}>
                            <LinearProgress />
                        </div>
                }
            </Dialog>
        </div>
    )
}

export default function StudentDetailComponent(props) {
    const [test, setTest] = React.useState();
    const [report, setReport] = React.useState();

    const classes = useStyles();

    const evaluateAnswers = (test, report) => {
        const questionData = test.questions.filter(
            // content items are not displayed
            body => !['image', 'video', 'text'].includes(body.type.toLowerCase()));
        questionData.forEach((question, questionNumber) => {
            // columns for student detail table
            question.studentResponse = [
                {response: ''},
                {response: ''},
                {response: ''},
                {response: ''},
                {response: ''},
                {response: ''},
                {response: ''},
                {response: ''},
                {response: ''},
                {response: ''}
            ];
            // multiple choice questions
            if (question.type === 'mc' || question.type === 'umc') {
                // now we go over the choices to set the icons
                question.multipleAnswerList.forEach((answer, answerNumber) => {
                    // for ungraded questions we display the answer if it was chosen
                    if (question.type === 'umc' && report.studentAnswers[questionNumber].studentResponse.includes((answerNumber + 1).toString())) {
                        answer.icon = 'ungraded';
                    // the correct answers are always shown
                    } else if (question.type === 'mc' && answer.answer) {
                        answer.icon = 'correct';
                    } else {
                        // if the answer was chosen but it was not correct it is displayed as incorrect
                        if (report.studentAnswers[questionNumber] && report.studentAnswers[questionNumber].studentResponse.includes((answerNumber + 1).toString())) {
                            answer.icon = 'incorrect';
                        // answers that were not chosen are hidden
                        } else {
                            answer.icon = 'hidden';
                        }
                    }
                });
                // we go over responses for the student detail table
                report.studentAnswers[questionNumber] && report.studentAnswers[questionNumber].studentResponse.forEach((response, n) => {
                    // we add all the selected choices to the student detail table
                    question.studentResponse[n].response = String.fromCharCode(64 + parseInt(response));
                    // ungraded multiple choice get a gray box
                    if (question.type === 'umc') {
                        question.studentResponse[n].correct = 'cast-form__student-detail-ungraded';
                    // if the choice is in the correct answer array it was correct and gets a green box
                    } else if (report.studentAnswers[questionNumber] && report.studentAnswers[questionNumber].correctAnswer.includes(response)) {
                        question.studentResponse[n].correct = 'cast-form__student-detail-correct';
                    // else the choice is incorrect and gets a red box
                    } else {
                        question.studentResponse[n].correct = 'cast-form__student-detail-incorrect';
                    }
                });
            // true false questions
            } else if (question.type === 't/f' || question.type === 'ut/f') {
                // we create an array with the choices
                question.answers = [{answerText: 'True'},{answerText: 'False'}];
                question.answers.forEach((answer, answerNumber) => {
                    // ungraded multiple choice get a gray box
                    if (question.type === 'ut/f' && parseInt(report.studentAnswers[questionNumber].studentResponse[0]) === answerNumber + 1) {
                        answer.icon = 'ungraded';
                    // if the choice matches the correct boolean answer it is correct
                    } else if (question.type === 't/f' && ((answerNumber === 0 && question.booleanAnswer) || (answerNumber === 1 && !question.booleanAnswer))) {
                        answer.icon = 'correct';
                    // if the response was correct the other choice is hidden
                    } else if (report.studentAnswers[questionNumber] && report.studentAnswers[questionNumber].responseWasCorrect) {
                        answer.icon = 'hidden';
                    // if it doesnt match the correct boolean answer and the response was incorrect the choice is incorrect
                    } else {
                        answer.icon = 'incorrect';
                    }
                    answer.key = answerNumber;
                });
                // a student response of 1 means True for t/f questions
                if (report.studentAnswers[questionNumber] && report.studentAnswers[questionNumber].studentResponse[0] === '1') {
                    question.studentResponse[0].response = 'T';
                // else the response is 2 which means false
                } else {
                    question.studentResponse[0].response = 'F';
                }
                // ungraded multiple choice get a gray box
                if (question.type === 'ut/f') {
                    question.studentResponse[0].correct = 'cast-form__student-detail-ungraded';
                // if the response was correct it gets a green box
                } else if (report.studentAnswers[questionNumber] && report.studentAnswers[questionNumber].responseWasCorrect) {
                    question.studentResponse[0].correct = 'cast-form__student-detail-correct';
                // else the choice is incorrect and gets a red box
                } else {
                    question.studentResponse[0].correct = 'cast-form__student-detail-incorrect';
                }
            // free form questions
            } else if (question.type === 'ff' || question.type === 'uff') {
                // we create an array with the response
                question.answers = [{answerText: report.studentAnswers[questionNumber].studentResponse[0]}];
                question.answers.forEach((answer, answerNumber) => {
                    // if the response is not graded it is hidden
                    if (typeof report.studentAnswers[questionNumber].responseWasCorrect !== 'boolean') {
                        answer.icon = 'hidden';
                    // if the response was correct it gets a green box
                    } else if (report.studentAnswers[questionNumber].responseWasCorrect) {
                        answer.icon = 'correct';
                        question.studentResponse[answerNumber].response = 'C';
                        question.studentResponse[answerNumber].correct = 'cast-form__student-detail-correct';
                    // else the choice is incorrect and gets a red box
                    } else {
                        answer.icon = 'incorrect';
                        question.studentResponse[answerNumber].response = 'I';
                        question.studentResponse[answerNumber].correct = 'cast-form__student-detail-incorrect';
                    }
                    answer.key = answerNumber;
                });
            }
        });
        setTest(questionData);
        setReport(report);
    }

    const fetchData = () => {
        if (props.isLoggedIn) {
            API.Tests.getTestsReports(props.match.params.id)
                .then(report => {
                    if (report.status === 200) {
                        API.Tests.getTests(props.match.params.id)
                            .then(({ data }) => {
                                evaluateAnswers(data, report.data[props.match.params.student]);
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line
    }, [props.isLoggedIn, props.match.params.id, props.match.params.student]);

    const renderIcon = (answer) => {
        return (
            <div className={`${answer.correct} ${classes.iconContainer}`}>
            {
                answer.response === 'C' ?
                <CheckIcon className={classes.icon} align='center' />
                :
                <CloseIcon className={classes.icon} />
            }
            </div>
        )
    } 

    return (
        <div className='cast-card cast-card--init'>
            {
                test && report && (
                    <div className='cast-form'>
                        <div className='cast-form__section-report'>
                            <div className='cast-form__title'>
                                {report.studentName}
                                <div className='cast-form__subtitle'>
                                    {report.phone}
                                </div>
                                <div className='cast-form__subtitle-student-detail'>
                                    <span>Score: {report.score === -1 ? 'Not yet graded' : report.score}</span><span>Time: {report.duration}</span>
                                </div>
                            </div>
                            <Link className='cast-toolbar__action' to={`/app/report/${props.match.params.id}/students`} exact="true">
                                <Button variant="contained">
                                    Back
                                </Button>
                            </Link>
                        </div>
                        <div className='cast-form__student-detail-table'>
                            <Table>
                                <TableBody>
                                    {test.map((body, idx) => {
                                        return (
                                            <TableRow key={idx}>
                                                <TableCell align="center">{`Question ${idx + 1}`}</TableCell>
                                                {body.studentResponse.map(answer => {
                                                    return (
                                                        <TableCell align="center">
                                                            {
                                                                body.type === 'ff' && answer.response ? renderIcon(answer)
                                                                :
                                                                <span className={answer.correct}>{answer.response}</span>
                                                            }
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                        <div>
                            {test.map((body, idx) => {
                                if (body.type.toLowerCase() === 't/f' || body.type.toLowerCase() === 'ut/f') {
                                    return (
                                        <div key={body.id} className={`cast-form-box ${typeof report.studentAnswers[idx].responseWasCorrect === 'boolean' ? report.studentAnswers[idx] && report.studentAnswers[idx].responseWasCorrect ? 'cast-form__correct-box' : 'cast-form__incorrect-box' : ''}`}>
                                            <div className='cast-form__field'>
                                                <TextField value={body.question}
                                                    multiline
                                                    className='cast-form__field'
                                                    label={`Question ${idx+1}`}
                                                    variant="outlined" />
                                                {body.answers.map(answer => {
                                                    return (
                                                        <div key={answer.key} className='cast-form__box'>
                                                            <AnswerIcon icon={answer.icon} />
                                                            <TextField value={answer.answerText}
                                                                multiline
                                                                className='cast-form__field'
                                                                variant="outlined" />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                } else if (body.type.toLowerCase() === 'mc' || body.type.toLowerCase() === 'umc') {
                                    return (
                                        <div key={body.id} className={`cast-form-box ${typeof report.studentAnswers[idx].responseWasCorrect === 'boolean' ? report.studentAnswers[idx] && report.studentAnswers[idx].responseWasCorrect ? 'cast-form__correct-box' : 'cast-form__incorrect-box' : ''}`}>
                                            <div className='cast-form__field'>
                                                <TextField value={body.question}
                                                    multiline
                                                    className='cast-form__field'
                                                    label={`Question ${idx+1}`}
                                                    variant="outlined" />
                                                {body.multipleAnswerList.map((multipleQuestion, idx) => {
                                                    if (multipleQuestion.answerText) {
                                                        return (
                                                            <div key={multipleQuestion.key} className='cast-form__box'>
                                                                <AnswerIcon icon={multipleQuestion.icon} />
                                                                <TextField value={multipleQuestion.answerText}
                                                                    multiline
                                                                    className='cast-form__field' label={String.fromCharCode(65 + idx)}
                                                                    variant="outlined" />
                                                            </div>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    )
                                } else if (body.type.toLowerCase() === 'ff') {
                                    return (
                                        <div key={body.id} className={`cast-form-box-ff ${report.studentAnswers[idx].responseWasCorrect !== null ? report.studentAnswers[idx].responseWasCorrect ? 'cast-form__correct-box' : 'cast-form__incorrect-box': ''}`}>
                                            <div className='cast-form__field'>
                                                <TextField value={body.question}
                                                    multiline
                                                    className='cast-form__field'
                                                    label={`Question ${idx+1}`}
                                                    variant="outlined" />
                                                {body.answers.map(answer => {
                                                    return (
                                                        <div key={answer.key} className='cast-form__box'>
                                                            <AnswerIcon icon={answer.icon} />
                                                            <TextField value={answer.answerText}
                                                                multiline
                                                                className='cast-form__field'
                                                                variant="outlined" />
                                                        </div>
                                                    )
                                                })}
                                                {report.studentAnswers[idx].responseWasCorrect === null &&
                                                    <GradingFreeForm question={idx}
                                                        report={report.id}
                                                        fetchData={fetchData} />
                                                }
                                            </div>
                                        </div>
                                    )
                                } else if (body.type.toLowerCase() === 'uff') {
                                    return (
                                        <div key={body.id} className='cast-form-box-ff'>
                                            <div className='cast-form__field'>
                                                <TextField value={body.question}
                                                    multiline
                                                    className='cast-form__field'
                                                    label={`Question ${idx+1} (Survey)`}
                                                    variant="outlined" />
                                                {body.answers.map(answer => {
                                                    return (
                                                        <div key={answer.key} className='cast-form__box'>
                                                            <AnswerIcon icon={answer.icon} />
                                                            <TextField value={answer.answerText}
                                                                multiline
                                                                className='cast-form__field'
                                                                variant="outlined" />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                } else return null;
                            })}
                        </div>
                    </div>
                )
            }
        </div>
    )
}